import CloseIcon from '@mui/icons-material/Close';
import dayjs from "dayjs";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {useRouter} from "@tanstack/react-router";
import Divider from "@mui/material/Divider";
import {SxProps} from "@mui/material/styles";
import {config} from "../config.ts";
import {IconWhatsapp} from "./icons/IconWhatsapp.tsx";
import {ClientCustomerOwnOrder} from "@tarimli-mono/api/src/shared/client-model/client-order.ts";
import React from "react";
import {useMutationCancelEditOrder} from "../services/api-order.ts";

const labels = {
  stage: (stage: number, totalStages: number) => `שלב ${stage} מתוך ${totalStages}`,
  next: 'ממשיכים',
  fillRequired: 'יש למלא את את השדות חובה לפני שממשיכים',
  change: 'עריכה',
  editingOrder: 'עדכון הובלה',
  editingOrderWarning: 'שימו ❤️ הצעות מחיר שהתקבלו יבוטלו, ואנו נבקש ממובילים להגיש הצעות מחיר מחדש',
  fromAddress: 'הבית שעוזבים',
  toAddress: 'הבית החדש',
  movingDate: 'מתי עוברים',
  orderSummary: 'סיכום ההובלה',
  cancelEdit: 'ביטול עדכון',
  choseItems: (count: string) => `בחרת ${count} פריטים`,
  packages: (count: string, orderComments: string) => <Stack gap={1}>
    <Typography>{count === '0' ? 'אין תכולה ארוזה' : `תכולה ארוזה: ${count} פריטים`}</Typography>
    <Typography>{`הערות להזמנה: ${orderComments || 'אין'}`}</Typography>
  </Stack>,
  customerService: (fontSize: number = 11, fontWeight: number = 600) => <Stack><Typography
    sx={{fontSize, fontWeight, lineHeight: 1, whiteSpace: 'nowrap'}}>דברו איתנו</Typography></Stack>,
  soFar: 'עד כאן אמרנו ככה:',
  submitError: 'סליחה קרתה תקלה 😔 נא לנסות שוב. אם הבעיה נמשכת נא ליצור קשר עם שירות הלקוחות שלנו.',
  error: 'סליחה תקלה :(',
}

export const CustomerServiceButton = ({title, variant, sx}: {
  title?: string,
  variant?: 'large' | 'small',
  sx?: SxProps
}) => {
  const isLarge = variant === 'large';
  const isSmall = variant === 'small';
  const onCustomerServiceClick = async () => {
    window.open(config().customerServiceLink, '_blank');
  };
  return (<Stack onClick={onCustomerServiceClick} direction={'row'} gap={1} sx={{
    cursor: 'pointer',
    alignItems: "center",
    backgroundColor: !isSmall ? "#25D366" : '#ffffff',
    outline: isSmall ? '1px solid #25D366' : 'none',
    // padding: isLarge ? 2 : 0.9,
    px: isLarge ? 3 : isSmall ? 0.8 : 1.5,
    py: isLarge ? 2.5 : isSmall ? 0.5 : 1.2,
    borderRadius: 1,
    color: !isSmall ? '#fff' : '#25D366',
    ...(sx || {}),
  }}>
    <Box>
      {labels.customerService(isLarge ? 25 : isSmall ? 11 : 14, isSmall ? 800 : 600)}
    </Box>
    <IconWhatsapp
      sx={{color: !isSmall ? '#fff' : '#25D366', fontSize: isLarge ? 30 : isSmall ? 14 : 20, marginBottom: '0'}}/>
  </Stack>);
}

export const OrderId = ({id}: { id: number }) => {
  return (
    <Typography sx={{
      // fontSize: 12,
      opacity: 0.5,
    }}>{`#${id}`}</Typography>
  );
}

export const EditHeader = ({order}: { order: ClientCustomerOwnOrder }) => {
  const router = useRouter();
  const cancelEditMutation = useMutationCancelEditOrder();
  const onClickCancelEdit = async () => {
    await cancelEditMutation.mutateAsync({orderId: order.id});
    setTimeout(async () => {
      // @ts-expect-error
      await router.navigate({
        to: `/orders`,
      });
    }, 50);
  }
  return (
    <Stack
      direction={'row'}
      gap={2}
      justifyContent={'space-between'}
      alignItems={'center'}
      sx={{
        backgroundColor: '#4b00ba',
        color: '#ffffff',
        borderRadius: 1,
        p: 2,
      }}
    >
      <Stack>
        <Typography sx={{
          fontWeight: 600,
          fontSize: 18,
        }}>{labels.editingOrder}</Typography>
        <Typography sx={{
          fontSize: 14,
        }}>{labels.editingOrderWarning}</Typography>
      </Stack>
      <LoadingButton
        sx={{
          borderColor: '#ffffff',
          color: '#ffffff',
        }}
        onClick={onClickCancelEdit}
        endIcon={<CloseIcon/>}
        loading={!order.isEdit} variant={'outlined'}>
        {labels.cancelEdit}
      </LoadingButton>
      {
        cancelEditMutation.isError && !cancelEditMutation.isPending ? <Typography sx={{
          fontSize: 12,
          opacity: 0.5,
        }}>{labels.error}</Typography> : null
      }
    </Stack>
  )
}
