import _ from 'lodash'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import {createFileRoute, useRouter} from '@tanstack/react-router'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import * as React from 'react'
import {useState} from 'react'
import {Controller, useForm, useWatch} from 'react-hook-form'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import numbro from 'numbro'
import Grid from '@mui/system/Grid'
import {usePartner} from '../../../services/auth.tsx'
import {
  useMutationCancelOffer,
  useMutationConfirmTerms,
  useMutationDeclineOrder,
  useMutationSubmitOffer,
} from '../../../services/api-partner.ts'
import {OrderNumber} from '../../../components/order-number.tsx'
import {V1_OrderSummary} from '../../../components/forms/v1-summary.tsx'
import {usePartnerRouteOrder} from '../../../services/hooks.ts'
import {NumericTextField} from '../../../components/NumericTextField.tsx'

export const Route = createFileRoute('/_auth/partner_/order_/$orderId')({
  component: PartnerOrderScreen,
})

const labels = {
  activeOrder: 'הזמנה פעילה',
  myOffer: 'הצעת המחיר שלי',
  nis: '₪',
  cancelOffer: 'ביטול הצעת מחיר',
  changeOffer: 'עריכת הצעת מחיר',
  confirm: 'אישור',
  cancel: 'ביטול',
  reallyCancelOffer: 'האם אתה בטוח שברצונך לבטל את ההצעה?',
  confirmCancelOffer: 'כן, בטל את ההצעה',
  close: 'סגור',
  amountMoving: 'עבור הובלה',
  total: 'סה״כ לתשלום ללקוח',
  priceBeforeVat: 'מחיר',
  vat: 'מע"מ 17%',
  addOffer: 'הגש הצעת מחיר',
  rejectOrder: 'דחה הזמנה',
  newOrder: 'הזמנה חדשה',
  send: 'שלח',
  continue: 'המשך',
  submitOfferTitle: 'הזן את סכום ההצעה שלך',
  confirmOfferTitle: 'אנא אשר את סכום ההצעה שלך',
  includingVat: 'כולל מע"מ',
  notIncludingVat: 'לא כולל מע"מ',
  chat: 'בירור',
  declineReason: 'מה סיבת הדחיה?',
  notAvailable: 'לא פנוי בתאריכים אלו',
  outOfZone: 'מחוץ לאיזור הפעילות',
  orderTooLarge: 'הזמנה גדולה מדי',
  orderTooSmall: 'הזמנה קטנה מדי',
  other: 'אחר',
  amountDisclaimer:
    'הסכומים חייבים לכלול את כל מרכיבי עלות ההובלה: פירוקים והרכבות ככל שנדרשים, גישה למשאית וכן מע״מ. אם הלקוח ביקש מנוף, אריזה, או חומרי אריזה - יש לציין סכומים בנפרד לכל שירות בבקשה.',
  offerDisclaimer: ' נעדכן אותך אם הלקוח אישר את ההזמנה',
  makeCorrection: 'תיקון',
  errorOtherReason: 'יש לציין סיבת דחיה',
  submitErrorMessage:
    'סליחה תקלה, אנא נסה שוב, אם הבעיה נמשכת נא לפנות לשירות הלקוחות',
  confirmOffer: (amount: number) => (
    <Typography>
      נא לאשר שליחת הצעת מחיר בסכום <em>{amount}₪</em>
    </Typography>
  ),
  orderFromArchive: (date: string) => `הזמנה מארכיון (${date})`,
  registrationNotCompletedText: () => (
    <Typography>
      לצערינו לא השלמת את הרישום למערכת ועל כן אינך יכול להגיש הצעות. על מנת
      להגיש הצעות עליך להשלים רישום, אנא פנה ל -{' '}
      <a href={`https://wa.me/${encodeURIComponent('+972524751656')}`}>
        052-475-1656
      </a>
    </Typography>
  ),
  iConfirmTheTerms: 'קראתי ואני מאשר',
  notAccepted: '(לא התקבלה)',
  termsNotConfirmedText: () => (
    <Typography>
      ברוך הבא לתריםלי!
      <br/>
      <br/>
      מעכשיו נתחיל לשלוח לך את כל הצעות המחיר שלנו. אנא זכור, מהירות תגובה היא
      שם המשחק.
      <br/>
      <br/>
      תריםלי שואפת להיות סוגרת העסקאות המהירה ביותר בשוק בשביל לתת חווית לקוח
      אופטימלית ועדכנית.
      <br/>
      <br/>
      אנא זכור את תנאי השימוש עליהם חתמת ובמיוחד את הדגשים הבאים:
      <br/>
      <br/>
      היה אדיב ושירותי: השירות שלנו מתבסס לא רק על מחיר, אלא גם על הדירוג שתקבל
      מהלקוחות. בסוף כל הובלה הלקוחות יתבקשו
      <br/>
      לדרג את טיב השירות שהם קיבלו ממך ואת איכות ההובלה, הניקוד הזה יהיה גלוי
      ללקוחות הבאים אחריהם ואלה יוכלו לבחור את
      <br/>
      המוביל שלהם עפ״י דירוג או מחיר.
      <br/>
      יש לעטוף את כל הרהיטים ומוצרי החשמל הגדולים בטרם העמסתם למשאית.
      <br/>
      יש להקפיד על עמידה בזמנים.
      <br/>
      אנא וודא שאתה קורא היטב את כל פרטי ההובלה בטרם הגשת הצעת מחיר על מנת למנוע
      אי נעימויות או מבוכות ודירוג נמוך.
      <br/>
      התשלום להובלות יבוצע דרך תריםלי ורק לאחר שהלקוח יאשר שההובלה בוצעה בהצלחה,
      כרטיסו יחוייב.
      <br/>
      אתה תקבל את התשלום המגיע לך על ההובלה מיד עם ביצוע החיוב, כלומר מחיר
      ההובלה בהפחתה של 10% עמלה לתריםלי ועמלת סליקה.
      <br/>
      <br/>
      <br/>
      בחרנו אותך בפינצטה ואנחנו סמוכים ובטוחים שיהיה לנו משתלם ונעים לעבוד יחד,
      אנחנו תמיד זמינים. בהצלחה!
      <br/>
      <br/>
    </Typography>
  ),
  amountCrane: 'עבור מנוף (בנפרד מהובלה)',
  amountPackingMaterials: 'עבור חומרי אריזה',
  amountPacking: 'עבור אריזה',
  amountUnpacking: 'עבור פריקה וסידור הבית',
  amountSmallMoving: 'עבור הובלה קטנה',
  amountHandyman: 'עבור שירותי תיקונים',
  amountLocksmith: 'עבור שירותי מנעולן',
  requiredMinAmount: 'מספר גדול מ-0 בבקשה',
}

const declineReasonOptions = [
  labels['notAvailable'],
  labels['outOfZone'],
  labels['orderTooLarge'],
  labels['orderTooSmall'],
  labels['other'],
]

const SubmittedOfferHeader = ({amount}: { amount: number }) => {
  const formattedAmount = numbro(amount).formatCurrency({
    thousandSeparated: true,
    mantissa: 0,
    currencySymbol: '₪',
  })
  return (
    <Stack>
      <Stack direction={'row'} alignItems={'center'} gap={2}>
        <Typography fontSize={20}>{labels.myOffer}</Typography>
        <Typography sx={{fontSize: 22, fontWeight: 600}}>
          {formattedAmount}
        </Typography>
      </Stack>
      {/*{order.isExpired && <Typography fontSize={15}>{labels.notAccepted}</Typography>}*/}
    </Stack>
  )
}

const AcceptedOfferHeader = ({amount}: { amount: number }) => {
  const formattedAmount = numbro(amount).formatCurrency({
    thousandSeparated: true,
    mantissa: 0,
    currencySymbol: '₪',
  })
  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      justifyContent={'center'}
      gap={2}
    >
      <Typography fontSize={20}>{labels.activeOrder}</Typography>
      <Typography sx={{fontSize: 22, fontWeight: 600}}>
        {formattedAmount}
      </Typography>
    </Stack>
  )
}

export function PartnerOrderScreen() {
  const partner = usePartner()
  const router = useRouter()
  const order = usePartnerRouteOrder(Route.fullPath)
  const orderId = order.id
  const sortedOffers = _.sortBy(order.offers, (o) => o.createdAt).reverse()
  const acceptedOffers = sortedOffers.filter((o) => o.status === 'accepted')
  const submittedOffers = sortedOffers.filter((o) => o.status === 'submitted')
  const cancelledOffers = sortedOffers.filter((o) => o.status === 'cancelled')
  if (acceptedOffers.length > 1) {
    throw new Error('More than one accepted offer')
  }
  const offer = acceptedOffers[0] || submittedOffers[0]

  const cancelOfferMutation = useMutationCancelOffer()
  const [cancelOfferDialogOpen, setCancelOfferDialogOpen] = useState(false)
  const [declineDialogOpen, setDeclineDialogOpen] = useState(false)
  const declineOrderMutation = useMutationDeclineOrder()
  const [submitDialogOpen, setSubmitDialogOpen] = useState(false)
  const [openAlertRegistrationIncomplete, setOpenAlertRegistrationIncomplete] =
    useState(false)
  const [openAlertTermsNotConfirmed, setOpenAlertTermsNotConfirmed] =
    useState(false)
  const isSubmittedOffer = offer && offer.status === 'submitted'
  const isAcceptedOffer = offer && offer.status === 'accepted'
  const canSubmitOffer = !offer
  const canCancelOffer = !!isSubmittedOffer
  const canDeclineOrder = !offer

  const onClickBack = async () => {
    await router.navigate({
      to: `/partner/orders`,
      replace: true,
    })
  }
  const handleCancelOffer = async () => {
    const result = await cancelOfferMutation.mutateAsync({offerId: offer.id})
    await router.navigate({
      to: `/partner/orders`,
    })
  }
  const handleCancelOfferOpen = () => {
    setCancelOfferDialogOpen(true)
  }
  const handleCancelOfferClose = () => {
    setCancelOfferDialogOpen(false)
  }
  const handleClickSubmitOpen = () => {
    if (partner.onboardingRegistrationCompleted) {
      setSubmitDialogOpen(true)
    } else {
      setOpenAlertRegistrationIncomplete(true)
    }
  }
  const handleSubmitClose = () => {
    setSubmitDialogOpen(false)
  }
  const handleDeclineClose = () => {
    setDeclineDialogOpen(false)
  }
  const onSubmitOffer = async () => {
    setTimeout(() => {
      router.navigate({
        to: `/partner/orders`,
      })
    }, 100)
  }
  const onSubmitDecline = async ({
                                   reason,
                                   text,
                                 }: {
    reason: string
    text?: string
  }) => {
    const message = reason === labels['other'] ? `${reason}: ${text}` : reason
    await declineOrderMutation.mutateAsync({
      orderId: order.id,
      message,
    })
    setTimeout(() => {
      router.navigate({
        to: `/partner/orders`,
      })
    }, 100)
  }
  const onClickDeclineOfferInvite = async () => {
    setDeclineDialogOpen(true)
  }

  return (
    <>
      <Stack
        gap={2}
        sx={{
          margin: 2,
          borderRadius: 1,
          backgroundColor: '#F5EDFF',
          padding: 2,
          overflow: 'auto',
        }}
      >
        <Stack gap={2}>
          <Stack direction={'row'} gap={2} alignItems={'center'}>
            <IconButton
              onClick={onClickBack}
              sx={{
                backgroundColor: '#EADCFF',
              }}
            >
              <ChevronRightIcon/>
            </IconButton>
            {isSubmittedOffer ? (
              <Box sx={{flexGrow: 1}}>
                <SubmittedOfferHeader amount={offer.amount}/>
              </Box>
            ) : null}
            {isAcceptedOffer ? (
              <Box sx={{flexGrow: 1}}>
                <AcceptedOfferHeader amount={offer.amount}/>
              </Box>
            ) : null}
            {canSubmitOffer ? (
              <Box sx={{flexGrow: 1}}>
                <Typography fontSize={20}>{labels['newOrder']}</Typography>
              </Box>
            ) : null}
            {/*{isArchiveOrder ? <Box sx={{flexGrow: 1}}><Typography*/}
            {/*  fontSize={20}>{labels.orderFromArchive(order.createdAt.format('DD/MM/YYYY'))}</Typography></Box> : null}*/}
            <OrderNumber orderId={order.id} pdfUrl={order.content.pdfFile}/>
          </Stack>
        </Stack>
        <Stack
          sx={{
            overflow: 'auto',
          }}
          gap={2}
        >
          <Box sx={{overflow: 'auto'}}>
            <V1_OrderSummary orderContent={order.content} partner={partner}/>
          </Box>
          {isSubmittedOffer ? (
            <Stack
              direction={'row'}
              gap={2}
              alignItems={'center'}
              justifyContent={'center'}
              sx={{
                p: 2,
              }}
            >
              <LoadingButton
                loading={cancelOfferMutation.isPending}
                sx={{alignSelf: 'flex-end', backgroundColor: 'red'}}
                variant={'contained'}
                onClick={handleCancelOfferOpen}
              >
                {labels.cancelOffer}
              </LoadingButton>
            </Stack>
          ) : null}
          {canSubmitOffer ? (
            <Box
              sx={{
                flexGrow: 0,
                flexShrink: 0,
              }}
            >
              <Stack
                direction={'row'}
                gap={2}
                sx={{
                  padding: 2,
                }}
              >
                <Button sx={{flex: 2}} onClick={handleClickSubmitOpen}>
                  {labels['addOffer']}
                </Button>
                {canDeclineOrder ? (
                  <Button
                    sx={{flex: 1}}
                    variant={'outlined'}
                    onClick={onClickDeclineOfferInvite}
                  >
                    {labels['rejectOrder']}
                  </Button>
                ) : null}
              </Stack>
            </Box>
          ) : null}
        </Stack>
      </Stack>
      {canCancelOffer ? (
        <CancelOfferDialog
          onConfirm={handleCancelOffer}
          open={cancelOfferDialogOpen}
          onClose={handleCancelOfferClose}
        />
      ) : null}
      {canSubmitOffer ? (
        <>
          <SubmitOfferDialog
            orderId={orderId}
            onSubmit={onSubmitOffer}
            open={submitDialogOpen}
            serviceNames={_.intersection(
              order.content.resolvedServices,
              partner.serviceNames,
            )}
            onClose={handleSubmitClose}
          />
          <DeclineDialog
            handleSubmit={onSubmitDecline}
            declineDialogOpen={declineDialogOpen}
            handleDeclineClose={handleDeclineClose}
          />
        </>
      ) : null}
      <AlertRegistrationIncomplete
        open={openAlertRegistrationIncomplete}
        handleClose={() => setOpenAlertRegistrationIncomplete(false)}
      />
      <AlertTermsNotConfirmed
        open={openAlertTermsNotConfirmed}
        handleClose={(confirmed: boolean) => {
          setOpenAlertTermsNotConfirmed(false)
          if (confirmed) {
            setSubmitDialogOpen(true)
          }
        }}
      />
    </>
  )
}

function CancelOfferDialog({open, onClose, onConfirm}) {
  return (
    <Dialog open={open} onClose={onClose}>
      <Stack sx={{alignItems: 'center', p: 2}} gap={4}>
        {labels.reallyCancelOffer}
        <Stack direction={'row'} gap={2} sx={{alignItems: 'center'}}>
          <Button onClick={onConfirm}>{labels.confirmCancelOffer}</Button>
          <Button variant={'outlined'} onClick={onClose}>
            {labels.close}
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  )
}

function SubmitOfferDialog({orderId, serviceNames, onSubmit, open, onClose}) {
  const partner = usePartner()
  const submitOfferMutation = useMutationSubmitOffer()
  // const [includingVat, setIncludingVat] = useState<null | 'yes' | 'no'>(null);
  const [mustGivePackingMaterials, setMustGivePackingMaterials] = useState<
    'yes' | 'no' | ''
  >('')
  const [mustGivePacking, setMustGivePacking] = useState<'yes' | 'no' | ''>('')
  const hasMovingService = serviceNames.includes('moving')
  const hasCraneService = serviceNames.includes('crane')
  const hasPackingMaterialsService = serviceNames.includes('packingMaterials')
  const hasPackingService = serviceNames.includes('packing')
  const hasUnpackingService = serviceNames.includes('unpacking')
  const hasSmallMovingService = serviceNames.includes('smallMoving')
  const hasHandymanService = serviceNames.includes('handyman')
  const hasLocksmithService = serviceNames.includes('locksmith')
  const [dialogShowing, setDialogShowing] = useState<'offer' | 'confirm'>(
    'offer',
  )
  const {
    register,
    control,
    handleSubmit,
    getValues,
    formState,
    watch,
    setValue,
    reset,
    trigger,
  } = useForm<{
    amountMoving: string,
    amountCrane: string,
    amountPackingMaterials: string,
    amountPacking: string,
    amountUnpacking: string,
    amountSmallMoving: string,
    amountHandyman: string,
    amountLocksmith: string,
  }>({
    defaultValues: {
      amountMoving: '',
      amountCrane: '',
      amountPackingMaterials: '',
      amountPacking: '',
      amountUnpacking: '',
      amountSmallMoving: '',
      amountHandyman: '',
      amountLocksmith: '',
    },
  })
  const watchAllFields = watch()
  const amountMoving = watchAllFields.amountMoving
  const amountCrane = watchAllFields.amountCrane
  const amountPackingMaterials = watchAllFields.amountPackingMaterials
  const amountPacking = watchAllFields.amountPacking
  const amountUnpacking = watchAllFields.amountUnpacking
  const amountSmallMoving = watchAllFields.amountSmallMoving
  const amountHandyman = watchAllFields.amountHandyman
  const amountLocksmith = watchAllFields.amountLocksmith
  const parsedAmountMoving = parseInt(amountMoving) || 0
  const parsedAmountCrane = parseInt(amountCrane) || 0
  const parsedAmountPackingMaterials = parseInt(amountPackingMaterials) || 0
  const parsedAmountPacking = parseInt(amountPacking) || 0
  const parsedAmountUnpacking = parseInt(amountUnpacking) || 0
  const parsedAmountSmallMoving = parseInt(amountSmallMoving) || 0
  const parsedAmountHandyman = parseInt(amountHandyman) || 0
  const parsedAmountLocksmith = parseInt(amountLocksmith) || 0
  const formattedAmountMoving =
    parsedAmountMoving > 0
      ? numbro(parsedAmountMoving).formatCurrency({
        thousandSeparated: true,
        mantissa: 0,
        currencySymbol: '₪',
      })
      : '-'
  const formattedAmountCrane =
    parsedAmountCrane > 0
      ? numbro(parsedAmountCrane).formatCurrency({
        thousandSeparated: true,
        mantissa: 0,
        currencySymbol: '₪',
      })
      : '-'
  const formattedAmountPackingMaterials =
    parsedAmountPackingMaterials > 0
      ? numbro(parsedAmountPackingMaterials).formatCurrency({
        thousandSeparated: true,
        mantissa: 0,
        currencySymbol: '₪',
      })
      : '-'
  const formattedAmountPacking =
    parsedAmountPacking > 0
      ? numbro(parsedAmountPacking).formatCurrency({
        thousandSeparated: true,
        mantissa: 0,
        currencySymbol: '₪',
      })
      : '-'
  const formattedAmountUnpacking =
    parsedAmountUnpacking > 0
      ? numbro(parsedAmountUnpacking).formatCurrency({
        thousandSeparated: true,
        mantissa: 0,
        currencySymbol: '₪',
      })
      : '-'
  const formattedAmountSmallMoving =
    parsedAmountSmallMoving > 0
      ? numbro(parsedAmountSmallMoving).formatCurrency({
        thousandSeparated: true,
        mantissa: 0,
        currencySymbol: '₪',
      })
      : '-'

  const formattedAmountHandyman =
    parsedAmountHandyman > 0
      ? numbro(parsedAmountHandyman).formatCurrency({
        thousandSeparated: true,
        mantissa: 0,
        currencySymbol: '₪',
      })
      : '-'

  const formattedAmountLocksmith =
    parsedAmountLocksmith > 0
      ? numbro(parsedAmountLocksmith).formatCurrency({
        thousandSeparated: true,
        mantissa: 0,
        currencySymbol: '₪',
      })
      : '-'

  const amountTotal = [
    parsedAmountMoving,
    parsedAmountCrane,
    parsedAmountPackingMaterials,
    parsedAmountPacking,
    parsedAmountUnpacking,
    parsedAmountSmallMoving,
    parsedAmountHandyman,
    parsedAmountLocksmith,
  ]
    .filter((a) => !!a)
    .reduce((a, b) => a + b, 0)
  const formattedAmountTotal = numbro(amountTotal).formatCurrency({
    thousandSeparated: true,
    mantissa: 0,
    currencySymbol: '₪',
  })
  const isValid = amountTotal > 0 && formState.isValid
  const onClickClose = () => {
    reset()
    // setIncludingVat(null);
    onClose()
    setDialogShowing('offer')
  }
  const onClickContinue = () => {
    setDialogShowing('confirm')
  }
  const onClickBack = () => {
    setDialogShowing('offer')
  }
  const onFormSubmit = async () => {
    await submitOfferMutation.mutateAsync({
      orderId,
      amountMoving: parsedAmountMoving,
      amountCrane: parsedAmountCrane,
      amountPackingMaterials: parsedAmountPackingMaterials,
      amountPacking: parsedAmountPacking,
      amountUnpacking: parsedAmountUnpacking,
      amountSmallMoving: parsedAmountSmallMoving,
      amountHandyman: parsedAmountHandyman,
      amountLocksmith: parsedAmountLocksmith,
      amountTotal,
    })
    onSubmit()
  }
  return (
    <Dialog open={open} onClose={onClose}>
      <>
        <Box
          sx={{
            display: dialogShowing === 'offer' ? 'block' : 'none',
            maxWidth: 400,
            overflow: 'auto',
          }}
        >
          <Stack sx={{padding: 2}} gap={2}>
            <Typography variant={'h6'}>{labels.submitOfferTitle}</Typography>
            <Divider/>
            {hasSmallMovingService ? (
              <Controller
                name="amountSmallMoving"
                rules={{
                  required: !!hasSmallMovingService,
                  min: {value: 1, message: labels.requiredMinAmount},
                }}
                control={control}
                render={({field, fieldState}) => {
                  return (
                    <>
                      <Stack gap={2}>
                        <Typography>
                          {labels.amountSmallMoving}{' '}
                          <span style={{color: 'red'}}>*</span>
                        </Typography>
                        <NumericTextField
                          helperText={
                            fieldState.invalid ? fieldState.error.message : ''
                          }
                          error={fieldState.invalid}
                          min={hasSmallMovingService ? 1 : 0}
                          {...field}
                        />
                        <Divider/>
                      </Stack>
                    </>
                  )
                }}
              />
            ) : null}
            {hasMovingService ? (
              <Controller
                name="amountMoving"
                rules={{
                  required: !!hasMovingService,
                  min: {value: 1, message: labels.requiredMinAmount},
                }}
                control={control}
                render={({field, fieldState}) => {
                  return (
                    <>
                      <Stack gap={2}>
                        <Typography sx={{color: 'red', fontWeight: 500}}>
                          {labels.amountDisclaimer}
                        </Typography>
                        <Typography>
                          {labels.amountMoving}{' '}
                          <span style={{color: 'red'}}>*</span>
                        </Typography>
                        <NumericTextField
                          helperText={
                            fieldState.invalid ? fieldState.error.message : ''
                          }
                          error={fieldState.invalid}
                          min={hasMovingService ? 1 : 0}
                          {...field}
                        />
                        <Divider/>
                      </Stack>
                    </>
                  )
                }}
              />
            ) : null}
            {hasCraneService ? (
              <Controller
                name="amountCrane"
                rules={{
                  required: !!hasCraneService,
                  min: {value: 1, message: labels.requiredMinAmount},
                }}
                control={control}
                render={({field, fieldState}) => {
                  return (
                    <>
                      <Stack gap={2}>
                        <Typography>
                          {labels.amountCrane}{' '}
                          <span style={{color: 'red'}}>*</span>
                        </Typography>
                        <NumericTextField
                          helperText={
                            fieldState.invalid ? fieldState.error.message : ''
                          }
                          error={fieldState.invalid}
                          min={hasCraneService ? 1 : 0}
                          {...field}
                        />
                        <Divider/>
                      </Stack>
                    </>
                  )
                }}
              />
            ) : null}
            {hasPackingMaterialsService ? (
              <Controller
                name="amountPackingMaterials"
                control={control}
                rules={{
                  required: mustGivePackingMaterials !== 'no',
                  min: {value: 1, message: labels.requiredMinAmount},
                }}
                render={({field, fieldState}) => {
                  return (
                    <>
                      <Stack>
                        <Typography>
                          הלקוח ביקש <strong>חומרי אריזה</strong>. האם תוכל לתת
                          הצעת מחיר לזה? <span style={{color: 'red'}}>*</span>
                        </Typography>
                        <RadioGroup
                          row
                          value={mustGivePackingMaterials}
                          onChange={async (e) => {
                            setMustGivePackingMaterials(
                              e.target.value as 'yes' | 'no',
                            )
                            if (e.target.value === 'no') {
                              setValue('amountPackingMaterials', '')
                            }
                            await trigger()
                          }}
                        >
                          <FormControlLabel
                            value={'yes'}
                            control={<Radio/>}
                            label={'כן'}
                          />
                          <FormControlLabel
                            value={'no'}
                            control={<Radio/>}
                            label={'לא'}
                          />
                        </RadioGroup>
                      </Stack>
                      {mustGivePackingMaterials === 'yes' ? (
                        <Stack gap={2}>
                          <Typography>
                            {labels.amountPackingMaterials}{' '}
                            <span style={{color: 'red'}}>*</span>
                          </Typography>
                          <NumericTextField
                            helperText={
                              fieldState.invalid ? fieldState.error.message : ''
                            }
                            error={fieldState.invalid}
                            min={0}
                            {...field}
                          />
                          <Divider/>
                        </Stack>
                      ) : null}
                    </>
                  )
                }}
              />
            ) : null}
            {hasPackingService ? (
              <Controller
                name="amountPacking"
                control={control}
                rules={{
                  required: mustGivePacking !== 'no',
                  min: {value: 1, message: labels.requiredMinAmount},
                }}
                render={({field, fieldState}) => {
                  return (
                    <>
                      <Stack>
                        <Typography>
                          הלקוח ביקש <strong>אריזה</strong>. האם תוכל לתת הצעת
                          מחיר לזה? <span style={{color: 'red'}}>*</span>
                        </Typography>
                        <RadioGroup
                          row
                          value={mustGivePacking}
                          onChange={async (e) => {
                            setMustGivePacking(e.target.value as 'yes' | 'no')
                            if (e.target.value === 'no') {
                              setValue('amountPacking', '')
                            }
                            await trigger()
                          }}
                        >
                          <FormControlLabel
                            value={'yes'}
                            control={<Radio/>}
                            label={'כן'}
                          />
                          <FormControlLabel
                            value={'no'}
                            control={<Radio/>}
                            label={'לא'}
                          />
                        </RadioGroup>
                      </Stack>
                      {mustGivePacking === 'yes' ? (
                        <Stack gap={2}>
                          <Typography>
                            {labels.amountPacking}{' '}
                            <span style={{color: 'red'}}>*</span>
                          </Typography>
                          <NumericTextField
                            helperText={
                              fieldState.invalid ? fieldState.error.message : ''
                            }
                            error={fieldState.invalid}
                            min={0}
                            {...field}
                          />
                          <Divider/>
                        </Stack>
                      ) : null}
                    </>
                  )
                }}
              />
            ) : null}
            {hasUnpackingService ? (
              <Controller
                name="amountUnpacking"
                rules={{
                  required: !!hasUnpackingService,
                  min: {value: 1, message: labels.requiredMinAmount},
                }}
                control={control}
                render={({field, fieldState}) => {
                  return (
                    <>
                      <Stack gap={2}>
                        <Typography>
                          {labels.amountUnpacking}{' '}
                          <span style={{color: 'red'}}>*</span>
                        </Typography>
                        <NumericTextField
                          helperText={
                            fieldState.invalid ? fieldState.error.message : ''
                          }
                          error={fieldState.invalid}
                          min={hasUnpackingService ? 1 : 0}
                          {...field}
                        />
                        <Divider/>
                      </Stack>
                    </>
                  )
                }}
              />
            ) : null}
            {hasHandymanService ? (
              <Controller
                name="amountHandyman"
                rules={{
                  required: !!hasHandymanService,
                  min: {value: 1, message: labels.requiredMinAmount},
                }}
                control={control}
                render={({field, fieldState}) => {
                  return (
                    <>
                      <Stack gap={2}>
                        <Typography>
                          {labels.amountHandyman}{' '}
                          <span style={{color: 'red'}}>*</span>
                        </Typography>
                        <NumericTextField
                          helperText={
                            fieldState.invalid ? fieldState.error.message : ''
                          }
                          error={fieldState.invalid}
                          min={hasHandymanService ? 1 : 0}
                          {...field}
                        />
                        <Divider/>
                      </Stack>
                    </>
                  )
                }}
              />
            ) : null}
            {hasLocksmithService ? (
              <Controller
                name="amountLocksmith"
                rules={{
                  required: !!hasLocksmithService,
                  min: {value: 1, message: labels.requiredMinAmount},
                }}
                control={control}
                render={({field, fieldState}) => {
                  return (
                    <>
                      <Stack gap={2}>
                        <Typography>
                          {labels.amountLocksmith}{' '}
                          <span style={{color: 'red'}}>*</span>
                        </Typography>
                        <NumericTextField
                          helperText={
                            fieldState.invalid ? fieldState.error.message : ''
                          }
                          error={fieldState.invalid}
                          min={hasLocksmithService ? 1 : 0}
                          {...field}
                        />
                        <Divider/>
                      </Stack>
                    </>
                  )
                }}
              />
            ) : null}

            <Stack direction={'row'} gap={2} sx={{alignItems: 'center'}}>
              <Button
                disabled={!isValid || formState.isSubmitting}
                sx={{flexGrow: 1}}
                onClick={onClickContinue}
              >
                {labels.continue}
              </Button>
              <Button variant={'outlined'} onClick={onClickClose}>
                {labels['cancel']}
              </Button>
            </Stack>
          </Stack>
        </Box>
        <Box
          sx={{
            display: dialogShowing === 'confirm' ? 'block' : 'none',
            maxWidth: 800,
          }}
        >
          <Stack sx={{padding: 2}} gap={2}>
            <Typography variant={'h6'}>{labels.confirmOfferTitle}</Typography>
            <Typography sx={{fontWeight: 500}}>
              {labels.offerDisclaimer}
            </Typography>
            <Grid container spacing={2} sx={{display: 'flex', flexGrow: 1}}>
              {hasMovingService ? (
                <>
                  <Grid
                    size={7}
                    sx={{display: 'flex', alignItems: 'center', flexGrow: 1}}
                  >
                    <Typography>{labels.amountMoving}</Typography>
                  </Grid>
                  <Grid size={5}>
                    <Typography variant={'h6'}>
                      {formattedAmountMoving}
                    </Typography>
                  </Grid>
                </>
              ) : null}
              {hasCraneService ? (
                <>
                  <Grid
                    size={7}
                    sx={{display: 'flex', alignItems: 'center', flexGrow: 1}}
                  >
                    <Typography>{labels.amountCrane}</Typography>
                  </Grid>
                  <Grid size={5}>
                    <Typography variant={'h6'}>
                      {formattedAmountCrane}
                    </Typography>
                  </Grid>
                </>
              ) : null}
              {hasPackingMaterialsService ? (
                <>
                  <Grid
                    size={7}
                    sx={{display: 'flex', alignItems: 'center', flexGrow: 1}}
                  >
                    <Typography>{labels.amountPackingMaterials}</Typography>
                  </Grid>
                  <Grid size={5}>
                    <Typography variant={'h6'}>
                      {formattedAmountPackingMaterials}
                    </Typography>
                  </Grid>
                </>
              ) : null}
              {hasPackingService ? (
                <>
                  <Grid
                    size={7}
                    sx={{display: 'flex', alignItems: 'center', flexGrow: 1}}
                  >
                    <Typography>{labels.amountPacking}</Typography>
                  </Grid>
                  <Grid size={5}>
                    <Typography variant={'h6'}>
                      {formattedAmountPacking}
                    </Typography>
                  </Grid>
                </>
              ) : null}
              {hasUnpackingService ? (
                <>
                  <Grid
                    size={7}
                    sx={{display: 'flex', alignItems: 'center', flexGrow: 1}}
                  >
                    <Typography>{labels.amountUnpacking}</Typography>
                  </Grid>
                  <Grid size={5}>
                    <Typography variant={'h6'}>
                      {formattedAmountUnpacking}
                    </Typography>
                  </Grid>
                </>
              ) : null}
              {hasSmallMovingService ? (
                <>
                  <Grid
                    size={7}
                    sx={{display: 'flex', alignItems: 'center', flexGrow: 1}}
                  >
                    <Typography>{labels.amountSmallMoving}</Typography>
                  </Grid>
                  <Grid size={5}>
                    <Typography variant={'h6'}>
                      {formattedAmountSmallMoving}
                    </Typography>
                  </Grid>
                </>
              ) : null}
              <Grid size={12}>
                <Divider/>
              </Grid>
              <Grid
                size={7}
                sx={{display: 'flex', alignItems: 'center', flexGrow: 1}}
              >
                <Typography>{labels.total}</Typography>
              </Grid>
              <Grid size={5}>
                <Typography variant={'h5'}>{formattedAmountTotal}</Typography>
              </Grid>
            </Grid>
            <Stack direction={'row'} gap={2} sx={{alignItems: 'center'}}>
              <LoadingButton
                variant={'contained'}
                disabled={!isValid || formState.isSubmitting}
                loading={formState.isSubmitting}
                sx={{flexGrow: 1}}
                onClick={handleSubmit(onFormSubmit)}
              >
                {labels.confirm}
              </LoadingButton>
              <Button variant={'outlined'} onClick={onClickBack}>
                {labels.makeCorrection}
              </Button>
            </Stack>
            {submitOfferMutation.isError ? (
              <Typography sx={{color: 'red'}}>
                {labels.submitErrorMessage}
              </Typography>
            ) : null}
          </Stack>
        </Box>
      </>
    </Dialog>
  )
}

function DeclineDialog({
                         handleSubmit,
                         declineDialogOpen,
                         handleDeclineClose,
                       }) {
  const form = useForm({
    mode: 'all',
    defaultValues: {
      selectedDeclineReason: '',
      declineReasonText: '',
    },
  })
  const selectedDeclineReason = useWatch({
    control: form.control,
    name: 'selectedDeclineReason', // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
    defaultValue: '', // default value before the render
  })
  const onSubmitDecline = async () => {
    await handleSubmit({
      reason: form.getValues().selectedDeclineReason,
      text: form.getValues().declineReasonText,
    })
    handleDeclineClose()
  }
  const onClose = () => {
    form.reset()
    handleDeclineClose()
  }
  return (
    <Dialog open={declineDialogOpen} onClose={handleDeclineClose}>
      <Stack sx={{padding: 2}} gap={2}>
        <Controller
          name="selectedDeclineReason"
          control={form.control}
          rules={{required: true}}
          render={({field, formState, fieldState}) => {
            return (
              <>
                <FormControl>
                  <FormLabel>
                    {labels['declineReason']}{' '}
                    <span style={{color: 'red'}}>*</span>
                  </FormLabel>
                  <RadioGroup
                    name="selectedDeclineReason"
                    value={field.value || ''}
                    onChange={(e) => {
                      field.onChange(e.target.value)
                    }}
                  >
                    {declineReasonOptions.map((option) => {
                      return (
                        <FormControlLabel
                          key={option}
                          control={<Radio value={option}/>}
                          label={option}
                        />
                      )
                    })}
                  </RadioGroup>
                </FormControl>
              </>
            )
          }}
        />
        <Box
          sx={{
            display:
              form.getValues().selectedDeclineReason === labels['other']
                ? 'block'
                : 'none',
          }}
        >
          <Controller
            name="declineReasonText"
            rules={{required: selectedDeclineReason === labels['other']}}
            control={form.control}
            render={({field, formState}) => (
              <>
                <TextField
                  required={
                    form.getValues().selectedDeclineReason === labels['other']
                  }
                  error={!!formState.errors?.selectedDeclineReason}
                  helperText={labels.errorOtherReason}
                  size={'small'}
                  {...field}
                  variant="outlined"
                />
              </>
            )}
          />
        </Box>
        <Stack direction={'row'} gap={2} sx={{alignItems: 'center'}}>
          <LoadingButton
            variant={'contained'}
            disabled={!form.formState.isValid}
            loading={form.formState.isSubmitting}
            sx={{flexGrow: 1}}
            onClick={form.handleSubmit(onSubmitDecline)}
          >
            {labels['send']}
          </LoadingButton>
          <Button variant={'outlined'} onClick={onClose}>
            {labels['cancel']}
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  )
}

function AlertRegistrationIncomplete({
                                       open,
                                       handleClose,
                                     }: {
  open: boolean
  handleClose: () => void
}) {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>{labels.registrationNotCompletedText()}</DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          {labels.close}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

function AlertTermsNotConfirmed({
                                  open,
                                  handleClose,
                                }: {
  open: boolean
  handleClose: (boolean) => void
}) {
  const mutationConfirmTerms = useMutationConfirmTerms()
  const handleConfirmTerms = async () => {
    await mutationConfirmTerms.mutateAsync()
    handleClose(true)
  }
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>
        <DialogContentText>{labels.termsNotConfirmedText()}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          variant={'contained'}
          loading={mutationConfirmTerms.isPending}
          sx={{flex: 1}}
          onClick={handleConfirmTerms}
          autoFocus
        >
          {labels.iConfirmTheTerms}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
