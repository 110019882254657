export const rules = {
  maxOrderDateFutureDays: 60,
  notificationsHoursStart: 7,
  notificationsHoursEnd: 19,
  draftOrderStaleHours: 8,
  submittedOrderStaleHours: 0.5,
  openOrderStaleHours: 1.5,
  submittedOfferStaleHours: 24 * 1000,
  'partner confirms late order within X hours': 0.5,
  'customer accepts offer within X hours': 1.5,
  'customer rates within X hours': 2,
  minOffersCount: 3,
  flagsHours: {
    staleDraft: 8,
    staleSubmitted: 0.5,
    staleRevertedSubmit: 2,
    insufficientOffers: 1.5,
  },
  beforeFinalDateRemindersDays: {
    reminder1: 30,
    reminder2: 7,
  },
  customerReminders: {
    staleDraftHours: [8, 16, 24],
    staleSubmittedHours: [0.5, 1.5, 16],
    staleRevertedSubmitHours: [2, 8, 24],
    notAccepted: [6, 24],
    notPaid: [12, 24, 36],
    notRated: [6, 24, 36],
  },
};

// export const isValidNotificationName = (name: string): name is NotificationName => {
//   return Object.keys(NotificationRules).includes(name);
// }

// export const NotificationRules = {
//   FStaleDraft: {
//     templateName: null,
//     groupNames: ['Flag', 'FlagStaleDraft'],
//     schedule: null,
//     delayHours: rules.flagsHours.staleDraft,
//     next: null,
//     guard: ({order, list}: { order: FullOrder, list: NotificationsList }) => {
//       if (order.data.status !== 'draft') {
//         throw new Error('order not draft');
//       }
//       if (order.statusLastChangedHoursAgo < rules.flagsHours.staleDraft) {
//         throw new Error('status change not old enough');
//       }
//       return true;
//     }
//   },
//   FStaleSubmitted: {
//     templateName: null,
//     groupNames: ['Flag', 'FlagStaleSubmitted'],
//     schedule: null,
//     delayHours: rules.flagsHours.staleSubmitted,
//     next: null,
//     guard: ({order, list}: { order: FullOrder, list: NotificationsList }) => {
//       if (order.data.status !== 'submitted') {
//         throw new Error('order not submitted');
//       }
//       if (order.statusLastChangedHoursAgo < rules.flagsHours.staleSubmitted) {
//         throw new Error('status change not old enough');
//       }
//       return true;
//     }
//   },
//   FStaleRevertedSubmit: {
//     templateName: null,
//     groupNames: ['Flag', 'FlagStaleRevertedSubmit'],
//     schedule: null,
//     delayHours: rules.flagsHours.staleRevertedSubmit,
//     next: null,
//     guard: ({order, list}: { order: FullOrder, list: NotificationsList }) => {
//       if (order.data.status !== 'draft') {
//         throw new Error('order not draft');
//       }
//       if (order.statusLastChangedHoursAgo < rules.flagsHours.staleRevertedSubmit) {
//         throw new Error('status change not old enough');
//       }
//       return true;
//     }
//   },
//   FInsufficientOffers: {
//     templateName: null,
//     groupNames: ['Flag', 'FlagInsufficientOffers'],
//     schedule: null,
//     delayHours: rules.flagsHours.insufficientOffers,
//     next: null,
//     guard: ({order, list}: { order: FullOrder, list: NotificationsList }) => {
//       if (order.data.status !== 'open') {
//         throw new Error('order not open');
//       }
//       if (order.submittedOrAcceptedOffers.length >= rules.minOffersCount) {
//         throw new Error('sufficient offers');
//       }
//       return true;
//     }
//   },
//  
//   OReachedSufficientOffers: {
//     templateName: null,
//     groupNames: ['OrderState', 'ReachedSufficientOffers'],
//     schedule: null,
//     delayHours: null,
//     next: null,
//     guard: ({order, list}: { order: FullOrder, list: NotificationsList }) => {
//       if (order.data.status !== 'open') {
//         throw new Error('order not open');
//       }
//       if (order.submittedOffers.length !== rules.minOffersCount) {
//         throw new Error('insufficient offers');
//       }
//       return true;
//     },
//   },
//  
//   PWelcome: {
//     templateName: 'PWelcome',
//     groupNames: [],
//     schedule: null,
//     delayHours: null,
//     next: null,
//     guard: null,
//   },
//   PNewOrder: {
//     templateName: 'PNewOrder',
//     groupNames: [],
//     schedule: null,
//     delayHours: null,
//     next: null,
//     guard: null,
//   },
//   POfferSubmitted: {
//     templateName: 'POfferSubmitted',
//     groupNames: [],
//     schedule: null,
//     delayHours: null,
//     next: null,
//     guard: null,
//   },
//   POfferAcceptedLate: {
//     templateName: 'POfferAcceptedLate',
//     groupNames: [],
//     schedule: null,
//     delayHours: null,
//     next: null,
//     guard: null,
//   },
//   POfferAccepted: {
//     templateName: 'POfferAccepted',
//     groupNames: [],
//     schedule: null,
//     delayHours: null,
//     next: null,
//     guard: null,
//   },
//   POrderCancelled: {
//     templateName: 'POrderCancelled',
//     groupNames: [],
//     schedule: null,
//     delayHours: null,
//     next: null,
//     guard: null,
//   },
//   POrderPaid: {
//     templateName: 'POrderPaid',
//     groupNames: [],
//     schedule: null,
//     delayHours: null,
//     next: null,
//     guard: null,
//   },
//
//   COrderSubmitted: {
//     templateName: 'COrderSubmitted',
//     groupNames: [],
//     schedule: null,
//     delayHours: null,
//     next: null,
//     guard: null,
//   },
//   COrderConfirmed: {
//     templateName: 'COrderConfirmed',
//     groupNames: [],
//     schedule: null,
//     delayHours: null,
//     next: null,
//     guard: null,
//   },
//   CFirstOffers: {
//     templateName: 'CFirstOffers',
//     groupNames: [],
//     schedule: null,
//     delayHours: null,
//     next: null,
//     guard: null,
//   },
//   CNoOffersYet: {
//     templateName: 'CNoOffersYet',
//     groupNames: [],
//     schedule: null,
//     delayHours: null,
//     next: null,
//     guard: null,
//   },
//   COneMoreOffer: {
//     templateName: 'COneMoreOffer',
//     groupNames: [],
//     schedule: null,
//     delayHours: null,
//     next: null,
//     guard: null,
//   },
//   COfferAccepted: {
//     templateName: 'COfferAccepted',
//     groupNames: [],
//     schedule: null,
//     delayHours: null,
//     next: null,
//     guard: null,
//   },
//   COfferAcceptedLate: {
//     templateName: 'COfferAcceptedLate',
//     groupNames: [],
//     schedule: null,
//     delayHours: null,
//     next: null,
//     guard: null,
//   },
//   CLateRejectedByPartner: {
//     templateName: 'CLateRejectedByPartner',
//     groupNames: [],
//     schedule: null,
//     delayHours: null,
//     next: null,
//     guard: null,
//   },
//   CLateAcceptedByPartner: {
//     templateName: 'CLateAcceptedByPartner',
//     groupNames: [],
//     schedule: null,
//     delayHours: null,
//     next: null,
//     guard: null,
//   },
//   COrderPaid: {
//     templateName: 'COrderPaid',
//     groupNames: [],
//     schedule: null,
//     delayHours: null,
//     next: null,
//     guard: null,
//   },
//   COrderCancelled: {
//     templateName: 'COrderCancelled',
//     groupNames: [],
//     schedule: null,
//     delayHours: null,
//     next: null,
//     guard: null,
//   },
//
//   CRemindStaleDraft1: {
//     groupNames: ['OrderReminder', 'RemindStaleDraft', 'OrderFlagReminder'],
//     templateName: 'CRemindStaleDraft1',
//     schedule: null,
//     delayHours: rules.customerReminders.staleDraftHours[0],
//     next: 'CRemindStaleDraft2',
//     guard: ({order, list}: { order: FullOrder, list: NotificationsList }) => {
//       if (order.data.status !== 'draft') {
//         throw new Error('order not draft');
//       }
//       if (order.statusLastChangedHoursAgo < rules.customerReminders.staleDraftHours[0]) {
//         throw new Error('status change not old enough');
//       }
//       return true;
//     },
//   },
//   CRemindStaleDraft2: {
//     groupNames: ['OrderReminder', 'RemindStaleDraft', 'OrderFlagReminder'],
//     templateName: 'CRemindStaleDraft1',
//     schedule: null,
//     delayHours: rules.customerReminders.staleDraftHours[1] - rules.customerReminders.staleDraftHours[0],
//     next: 'CRemindStaleDraft3',
//     guard: ({order, list}: { order: FullOrder, list: NotificationsList }) => {
//       if (order.data.status !== 'draft') {
//         throw new Error('order not draft');
//       }
//       if (order.statusLastChangedHoursAgo < rules.customerReminders.staleDraftHours[1]) {
//         throw new Error('status change not old enough');
//       }
//       return true;
//     },
//   },
//   CRemindStaleDraft3: {
//     groupNames: ['OrderReminder', 'RemindStaleDraft', 'OrderFlagReminder'],
//     templateName: 'CRemindStaleDraft2',
//     schedule: null,
//     delayHours: rules.customerReminders.staleDraftHours[2] - rules.customerReminders.staleDraftHours[1],
//     next: null,
//     guard: ({order, list}: { order: FullOrder, list: NotificationsList }) => {
//       if (order.data.status !== 'draft') {
//         throw new Error('order not draft');
//       }
//       if (order.statusLastChangedHoursAgo < rules.customerReminders.staleDraftHours[2]) {
//         throw new Error('status change not old enough');
//       }
//       return true;
//     },
//   },
//   CRemindStaleSubmitted1: {
//     groupNames: ['OrderReminder', 'RemindStaleSubmitted', 'OrderFlagReminder'],
//     templateName: 'CRemindStaleSubmitted1',
//     schedule: null,
//     delayHours: rules.customerReminders.staleSubmittedHours[0],
//     next: 'CRemindStaleSubmitted2',
//     guard: ({order, list}: { order: FullOrder, list: NotificationsList }) => {
//       if (order.data.status !== 'submitted') {
//         throw new Error('order not submitted');
//       }
//       if (order.statusLastChangedHoursAgo < rules.customerReminders.staleSubmittedHours[0]) {
//         throw new Error('status change not old enough');
//       }
//       return true;
//     }
//   },
//   CRemindStaleSubmitted2: {
//     groupNames: ['OrderReminder', 'RemindStaleSubmitted', 'OrderFlagReminder'],
//     templateName: 'CRemindStaleSubmitted2',
//     schedule: null,
//     delayHours: rules.customerReminders.staleSubmittedHours[1] - rules.customerReminders.staleSubmittedHours[0],
//     next: null,
//     guard: ({order, list}: { order: FullOrder, list: NotificationsList }) => {
//       if (order.data.status !== 'submitted') {
//         throw new Error('order not submitted');
//       }
//       if (order.statusLastChangedHoursAgo < rules.customerReminders.staleSubmittedHours[1]) {
//         throw new Error('status change not old enough');
//       }
//       return true;
//     }
//   },
//
//
//   CRemindStaleRevertedSubmit1: {
//     groupNames: ['OrderReminder', 'CRemindStaleRevertedSubmit', 'OrderFlagReminder'],
//     templateName: 'CRemindStaleRevertedSubmit1',
//     schedule: null,
//     delayHours: rules.customerReminders.staleRevertedSubmitHours[0],
//     next: 'CRemindStaleRevertedSubmit2',
//     guard: ({order, list}: { order: FullOrder, list: NotificationsList }) => {
//       if (order.data.status !== 'draft') {
//         throw new Error('order not draft');
//       }
//       if (order.statusLastChangedHoursAgo < rules.customerReminders.staleRevertedSubmitHours[0]) {
//         throw new Error('status change not old enough');
//       }
//       return true
//     }
//   },
//   CRemindStaleRevertedSubmit2: {
//     groupNames: ['OrderReminder', 'CRemindStaleRevertedSubmit', 'OrderFlagReminder'],
//     templateName: 'CRemindStaleRevertedSubmit1',
//     schedule: null,
//     delayHours: rules.customerReminders.staleRevertedSubmitHours[1] - rules.customerReminders.staleRevertedSubmitHours[0],
//     next: 'CRemindStaleRevertedSubmit3',
//     guard: ({order, list}: { order: FullOrder, list: NotificationsList }) => {
//       if (order.data.status !== 'draft') {
//         throw new Error('order not draft');
//       }
//       if (order.statusLastChangedHoursAgo < rules.customerReminders.staleRevertedSubmitHours[1]) {
//         throw new Error('status change not old enough');
//       }
//       return true;
//     }
//   },
//   CRemindStaleRevertedSubmit3: {
//     groupNames: ['OrderReminder', 'CRemindStaleRevertedSubmit', 'OrderFlagReminder'],
//     templateName: 'CRemindStaleRevertedSubmit1',
//     schedule: null,
//     delayHours: rules.customerReminders.staleRevertedSubmitHours[2] - rules.customerReminders.staleRevertedSubmitHours[1],
//     next: null,
//     guard: ({order, list}: { order: FullOrder, list: NotificationsList }) => {
//       if (order.data.status !== 'draft') {
//         throw new Error('order not draft');
//       }
//       if (order.statusLastChangedHoursAgo < rules.customerReminders.staleRevertedSubmitHours[2]) {
//         throw new Error('status change not old enough');
//       }
//       return true;
//     }
//   },
//
//
//   CRemindNotAccepted1: {
//     groupNames: ['OrderReminder', 'RemindNotAccepted', 'OrderFlagReminder'],
//     templateName: 'CRemindNotAccepted1',
//     schedule: null,
//     delayHours: rules.customerReminders.notAccepted[0],
//     next: 'CRemindNotAccepted2',
//     guard: ({order, list}: { order: FullOrder, list: NotificationsList }) => {
//       if (order.data.status !== 'submitted') {
//         throw new Error('order not submitted');
//       }
//       if (order.statusLastChangedHoursAgo < rules.customerReminders.notAccepted[0]) {
//         throw new Error('status change not old enough');
//       }
//       return true;
//     }
//   },
//   CRemindNotAccepted2: {
//     groupNames: ['OrderReminder', 'RemindNotAccepted', 'OrderFlagReminder'],
//     templateName: 'CRemindNotAccepted2',
//     schedule: null,
//     delayHours: rules.customerReminders.notAccepted[1] - rules.customerReminders.notAccepted[0],
//     next: null,
//     guard: ({order, list}: { order: FullOrder, list: NotificationsList }) => {
//       if (order.data.status !== 'submitted') {
//         throw new Error('order not submitted');
//       }
//       if (order.statusLastChangedHoursAgo < rules.customerReminders.notAccepted[1]) {
//         throw new Error('status change not old enough');
//       }
//       return true;
//     }
//   },
//
//   CRemindNotPaid1: {
//     groupNames: ['OrderReminder', 'RemindNotPaid', 'OrderFlagReminder'],
//     templateName: 'CRemindNotPaid1',
//     schedule: null,
//     delayHours: rules.customerReminders.notPaid[0],
//     next: 'CRemindNotPaid2',
//     guard: ({order, list}: { order: FullOrder, list: NotificationsList }) => {
//       if (order.data.status !== 'closed') {
//         throw new Error('order not closed');
//       }
//       const hoursSinceFinalDate = dayjs().diff(dayjs(order.data.finalDate), 'hours');
//       if (hoursSinceFinalDate < rules.customerReminders.notPaid[0]) {
//         throw new Error('final date not old enough');
//       }
//       if (hoursSinceFinalDate > rules.customerReminders.notPaid[1]) {
//         throw new Error('final date too old');
//       }
//       return true;
//     }
//   },
//   CRemindNotPaid2: {
//     groupNames: ['OrderReminder', 'RemindNotPaid', 'OrderFlagReminder'],
//     templateName: 'CRemindNotPaid1',
//     schedule: null,
//     delayHours: rules.customerReminders.notPaid[1] - rules.customerReminders.notPaid[0],
//     next: 'CRemindNotPaid3',
//     guard: ({order, list}: { order: FullOrder, list: NotificationsList }) => {
//       if (order.data.status !== 'closed') {
//         throw new Error('order not closed');
//       }
//       const hoursSinceFinalDate = dayjs().diff(dayjs(order.data.finalDate), 'hours');
//       if (hoursSinceFinalDate < rules.customerReminders.notPaid[1]) {
//         throw new Error('final date not old enough');
//       }
//       if (hoursSinceFinalDate > rules.customerReminders.notPaid[2]) {
//         throw new Error('final date too old');
//       }
//       return true;
//     }
//   },
//   CRemindNotPaid3: {
//     groupNames: ['OrderReminder', 'RemindNotPaid', 'OrderFlagReminder'],
//     templateName: 'CRemindNotPaid1',
//     schedule: null,
//     delayHours: rules.customerReminders.notPaid[2] - rules.customerReminders.notPaid[1],
//     next: null,
//     guard: ({order, list}: { order: FullOrder, list: NotificationsList }) => {
//       if (order.data.status !== 'closed') {
//         throw new Error('order not closed');
//       }
//       const hoursSinceFinalDate = dayjs().diff(dayjs(order.data.finalDate), 'hours');
//       if (hoursSinceFinalDate < rules.customerReminders.notPaid[2]) {
//         throw new Error('final date not old enough');
//       }
//       return true;
//     }
//   },
//
//   CRemindNotRated1: {
//     groupNames: ['OrderReminder', 'RemindNotRated', 'OrderFlagReminder'],
//     templateName: 'CRemindNotRated1',
//     schedule: null,
//     delayHours: rules.customerReminders.notRated[0],
//     next: 'CRemindNotRated2',
//     guard: ({order, list}: { order: FullOrder, list: NotificationsList }) => {
//       if (order.data.status !== 'completed') {
//         throw new Error('order not completed');
//       }
//       if (order.data.ratedAt) {
//         throw new Error('order already rated');
//       }
//       if (order.statusLastChangedHoursAgo < rules.customerReminders.notRated[0]) {
//         throw new Error('status change not old enough');
//       }
//       if (order.statusLastChangedHoursAgo > rules.customerReminders.notRated[1]) {
//         throw new Error('status change too old');
//       }
//       return true;
//     }
//   },
//   CRemindNotRated2: {
//     groupNames: ['OrderReminder', 'RemindNotRated', 'OrderFlagReminder'],
//     templateName: 'CRemindNotRated1',
//     schedule: null,
//     delayHours: rules.customerReminders.notRated[1] - rules.customerReminders.notRated[0],
//     next: 'CRemindNotRated3',
//     guard: ({order, list}: { order: FullOrder, list: NotificationsList }) => {
//       if (order.data.status !== 'completed') {
//         throw new Error('order not completed');
//       }
//       if (order.data.ratedAt) {
//         throw new Error('order already rated');
//       }
//       if (order.statusLastChangedHoursAgo < rules.customerReminders.notRated[1]) {
//         throw new Error('status change not old enough');
//       }
//       if (order.statusLastChangedHoursAgo > rules.customerReminders.notRated[2]) {
//         throw new Error('status change too old');
//       }
//       return true;
//     }
//   },
//   CRemindNotRated3: {
//     groupNames: ['OrderReminder', 'RemindNotRated', 'OrderFlagReminder'],
//     templateName: 'CRemindNotRated1',
//     schedule: null,
//     delayHours: rules.customerReminders.notRated[2] - rules.customerReminders.notRated[1],
//     next: null,
//     guard: ({order, list}: { order: FullOrder, list: NotificationsList }) => {
//       if (order.data.status !== 'completed') {
//         throw new Error('order not completed');
//       }
//       if (order.data.ratedAt) {
//         throw new Error('order already rated');
//       }
//       if (order.statusLastChangedHoursAgo < rules.customerReminders.notRated[2]) {
//         throw new Error('status change not old enough');
//       }
//       return true;
//     }
//   },
//
//
//   CRemindFinalDate1: {
//     groupNames: ['OrderReminder', 'RemindFinalDate'],
//     templateName: 'CRemindFinalDate1',
//     schedule: null,
//     delayHours: null,
//     next: 'CRemindFinalDate2',
//     guard: ({order, list}: { order: FullOrder, list: NotificationsList }) => {
//       if (order.data.status !== 'closed') {
//         throw new Error('order not closed');
//       }
//       const hoursTillFinalDate = dayjs(order.data.finalDate).diff(dayjs(), 'hours');
//       if (hoursTillFinalDate < 0) {
//         throw new Error('final date in the past');
//       }
//       return true;
//     },
//   },
//   CRemindFinalDate2: {
//     groupNames: ['OrderReminder', 'RemindFinalDate'],
//     templateName: 'CRemindFinalDate1',
//     schedule: null,
//     delayHours: null,
//     next: 'CRemindFinalDate3',
//     guard: ({order, list}: { order: FullOrder, list: NotificationsList }) => {
//       if (order.data.status !== 'closed') {
//         throw new Error('order not closed');
//       }
//       const hoursTillFinalDate = dayjs(order.data.finalDate).diff(dayjs(), 'hours');
//       if (hoursTillFinalDate < 0) {
//         throw new Error('final date in the past');
//       }
//       return true;
//     },
//   },
//   CRemindFinalDate3: {
//     groupNames: ['OrderReminder', 'RemindFinalDate'],
//     templateName: 'CRemindFinalDate1',
//     schedule: null,
//     delayHours: null,
//     next: null,
//     guard: ({order, list}: { order: FullOrder, list: NotificationsList }) => {
//       if (order.data.status !== 'closed') {
//         throw new Error('order not closed');
//       }
//       const hoursTillFinalDate = dayjs(order.data.finalDate).diff(dayjs(), 'hours');
//       if (hoursTillFinalDate < 0) {
//         throw new Error('final date in the past');
//       }
//       return true;
//     },
//   },
// }
//
// export type NotificationRule = typeof NotificationRules[keyof typeof NotificationRules];
// export type NotificationName = keyof typeof NotificationRules;
