import * as Sentry from "@sentry/react";
import theme, {cacheRtl} from "./theme.ts";
import {AuthProvider, useAuth} from "./services/auth.tsx";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import CssBaseline from "@mui/material/CssBaseline";
import {createRouter, RouterProvider} from "@tanstack/react-router";
import {routeTree} from "./routeTree.gen.ts";
import {Suspense} from "react";
import {CacheProvider} from "@emotion/react";
import {config} from "./config.ts";
import {ScreenLoader} from "./components/ScreenLoader.tsx";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import {PostHogProvider} from "./posthog.tsx";

const queryClient = new QueryClient();

const router = createRouter({
  routeTree,
  context: {
    auth: undefined!,
    queryClient: undefined!,
  },
  defaultPreload: 'intent',
  defaultNotFoundComponent: NotFoundComponent,
  defaultPreloadStaleTime: 0,
});
// Register the router instance for type safety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router,
  }
}

Sentry.addIntegration(Sentry.tanstackRouterBrowserTracingIntegration(router));

function NotFoundComponent() {
  return (
    <div style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      maxWidth: "400px",
      margin: '0 auto',
      padding: '20px',
      background: 'white',
      borderRadius: '10px',
    }}>
      <h2 style={{margin: 0}}>הדף לא נמצא 😔</h2>
      <p>אנחנו מתנצלים, אבל הדף שחיפשת לא נמצא</p>
      <button style={{
        borderRadius: '5px',
        textDecoration: 'underline',
        fontSize: 20,
        paddingLeft: '15px',
        paddingRight: '15px',
        paddingTop: '5px',
        paddingBottom: '5px'
      }} onClick={() => {
        location.href = '/';
      }}>חזרה לעמוד הבית
      </button>
    </div>
  );
}

function ErrorComponent({error, reset}) {
  return (
    <Stack
      gap={2}
      style={{
        justifyContent: "center",
        maxWidth: "400px",
        margin: '0 auto',
        padding: '20px',
        background: 'white',
        borderRadius: '10px',
      }}>
      <h2 style={{margin: 0}}>שגיאה</h2>
      <pre style={{
        color: 'red',
        padding: '5px',
        maxWidth: '100%',
        whiteSpace: 'break-spaces',
        direction: 'ltr',
        fontSize: 12
      }}>{error.message}</pre>
      <Button onClick={() => {
        location.href = '/';
      }}>נסו שוב
      </Button>
      <a href={config().customerServiceLink}>שירות הלקוחות שלנו בוואצאפ: 051-520-7969</a>
    </Stack>
  );
}

const RouterApp = () => {
  const auth = useAuth()
  const routerApp = <RouterProvider defaultErrorComponent={ErrorComponent} router={router}
                                    context={{queryClient, auth}}/>;
  return <>
    {routerApp}
  </>;
}

export const App = () => {
  return (
    <PostHogProvider>
      <QueryClientProvider client={queryClient}>
        <CacheProvider value={cacheRtl}>
          <ThemeProvider theme={theme}>
            <CssBaseline/>
            <Suspense fallback={<ScreenLoader/>}>
              <AuthProvider>
                <RouterApp/>
              </AuthProvider>
            </Suspense>
          </ThemeProvider>
        </CacheProvider>
      </QueryClientProvider>
    </PostHogProvider>
  );
}
