export const PartnerServices = {
  moving: 'moving',
  packingMaterials: 'packingMaterials',
  packing: 'packing',
  crane: 'crane',
  unpacking: 'unpacking',
  smallMoving: 'smallMoving',
  handyman: 'handyman',
  locksmith: 'locksmith',
};

export const PartnerServiceGroups = {
  moving: {
    moving: 'moving',
    crane: 'crane',
    packingMaterials: 'packingMaterials',
    packing: 'packing',
  },
  unpacking: {
    unpacking: 'unpacking',
  },
  smallMoving: {
    smallMoving: 'smallMoving',
    crane: 'crane',
  },
  handyman: {
    handyman: 'handyman',
  },
  locksmith: {
    locksmith: 'locksmith',
  },
};

export const ServiceNamesLabels = {
  [PartnerServices.moving]: 'הובלה',
  [PartnerServices.crane]: 'מנוף',
  [PartnerServices.packingMaterials]: 'חומרי אריזה',
  [PartnerServices.packing]: 'אריזה',
  [PartnerServices.unpacking]: 'פריקה וסידור הבית',
  [PartnerServices.smallMoving]: 'הובלה קטנה',
  [PartnerServices.handyman]: 'הנדימן',
  [PartnerServices.locksmith]: 'מנעולן',
};
export const ServiceNamesShortLabels = {
  [PartnerServices.moving]: 'הובלה',
  [PartnerServices.crane]: 'מנוף',
  [PartnerServices.packingMaterials]: 'חומרי-א',
  [PartnerServices.packing]: 'אריזה',
  [PartnerServices.unpacking]: 'פריקה',
  [PartnerServices.smallMoving]: 'ה-קטנה',
  [PartnerServices.handyman]: 'הנדימן',
  [PartnerServices.locksmith]: 'מנעולן',
};
export const ServiceNamesTinyLabels = {
  [PartnerServices.moving]: 'הובל',
  [PartnerServices.crane]: 'מנף',
  [PartnerServices.packingMaterials]: 'חמ-א',
  [PartnerServices.packing]: 'ארז',
  [PartnerServices.unpacking]: 'פרק',
  [PartnerServices.smallMoving]: 'הקטנ',
  [PartnerServices.handyman]: 'הנדי',
  [PartnerServices.locksmith]: 'מנעל',
};

export const ServiceGroupNamesLabels = {
  moving: 'הובלה',
  unpacking: 'פריקה וסידור הבית',
  smallMoving: 'הובלה קטנה',
  handyman: 'הנדימן',
  locksmith: 'מנעולן',
};

export const ServiceNames = Object.keys(PartnerServices) as ServiceName[];
export type ServiceName = keyof typeof PartnerServices;

export const ServiceGroupNames = Object.keys(PartnerServiceGroups) as ServiceGroupName[];
export type ServiceGroupName = keyof typeof PartnerServiceGroups;

export type ServiceType = 'moving' | 'smallMoving';

export const getServiceGroupByService = (service: ServiceName): ServiceGroupName | null => {
  for (const groupName of ServiceGroupNames) {
    if (PartnerServiceGroups[groupName][service]) {
      return groupName;
    }
  }
  return null;
};

export const serviceGroupsForServiceNames = (serviceNames: ServiceName[]): ServiceGroupName[] => {
  const serviceGroups = new Set<ServiceGroupName>();
  for (const serviceName of serviceNames) {
    const group = getServiceGroupByService(serviceName);
    if (group && !serviceGroups.has(group)) {
      serviceGroups.add(group);
    }
  }
  return Array.from(serviceGroups);
};