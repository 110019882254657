import {TextField, TextFieldProps} from "@mui/material";
import * as React from "react";
import {useEffect, useRef} from "react";

type Props = TextFieldProps & {
  min?: number;
  max?: number;
}

export const NumericTextField = (props: Props) => {
  const textFieldRef = useRef(null)
  useEffect(() => {
    const handleWheel = e => e?.preventDefault();
    textFieldRef?.current?.addEventListener('wheel', handleWheel)

    return () => {
      textFieldRef?.current?.removeEventListener('wheel', handleWheel)
    }
  }, [])
  return (
    <TextField
      // ref={textFieldRef}
      inputRef={textFieldRef}
      // onWheel={(e) => {
      //   (e?.target as any)?.blur!()
      //   e?.stopPropagation();
      //   requestAnimationFrame(() => {
      //     (e?.target as any)?.focus!()
      //   })
      // }}
      autoComplete="off"
      autoCorrect="off"
      autoCapitalize="off"
      type={'number'}
      slotProps={{
        htmlInput: {
          inputMode: 'numeric',
          pattern: '[0-9]*',
          min: props.min,
          max: props.max,
        }
      }}
      {...props} />
  );
}