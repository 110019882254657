import dayjs from 'dayjs';
import {ICContractStatus, ICOfferForCustomer, ICOfferQuote, ICOfferStatus, ICPartnerOwnOffer} from '../client-types';
import {ClientPartnerPublicProfile} from './client-partner';
import {ServiceName} from '../partner-service';

export class ClientOfferForCustomer {
  id: number;
  orderId: number;
  partner: ClientPartnerPublicProfile;
  status: ICOfferStatus;
  serviceNames: ServiceName[];
  quotes: ICOfferQuote[];
  amount: number;
  createdAt: dayjs.Dayjs;
  updatedAt: dayjs.Dayjs;

  constructor(offer: ICOfferForCustomer) {
    this.id = offer.id;
    this.orderId = offer.orderId;
    this.partner = new ClientPartnerPublicProfile(offer.partner);
    this.status = offer.status;
    this.serviceNames = offer.serviceNames;
    this.quotes = offer.quotes;
    this.amount = offer.amount;
    this.createdAt = dayjs(offer.createdAt);
    this.updatedAt = dayjs(offer.updatedAt);
  }
  
  get serviceGroup() {
    if (this.serviceNames.includes('moving')) {
      return 'moving';
    }
    if (this.serviceNames.includes('smallMoving')) {
      return 'smallMoving';
    }
    if (this.serviceNames.includes('unpacking')) {
      return 'unpacking';
    }
    if (this.serviceNames.includes('handyman')) {
      return 'handyman';
    }
    if (this.serviceNames.includes('locksmith')) {
      return 'locksmith';
    }
    throw new Error(`Contract: Unexpected service group. Offer serviceNames: ${this.serviceNames.join(', ')}`);
  }
  
  isServiceGroup(serviceGroup: 'moving' | 'smallMoving' | 'unpacking' | 'handyman' | 'locksmith') {
    return this.serviceGroup === serviceGroup;
  }
  
  oneOfServiceGroups(serviceGroups: ('moving' | 'smallMoving' | 'unpacking' | 'handyman' | 'locksmith')[]) {
    return serviceGroups.includes(this.serviceGroup);
  }
}

export class ClientPartnerOwnOffer {
  id: number;
  amount: number;
  orderId: number;
  status: ICOfferStatus;
  serviceNames: string[];
  quotes: ICOfferQuote[];
  contract: {
    id: number;
    status: ICContractStatus;
    finalAmount: number;
    executionDate: dayjs.Dayjs;
    createdAt: dayjs.Dayjs;
    updatedAt: dayjs.Dayjs;
  } | null;
  createdAt: dayjs.Dayjs;
  updatedAt: dayjs.Dayjs;
  
  constructor(offer: ICPartnerOwnOffer) {
    this.id = offer.id;
    this.amount = offer.amount;
    this.orderId = offer.orderId;
    this.status = offer.status;
    this.serviceNames = offer.serviceNames;
    this.quotes = offer.quotes;
    this.contract = offer.contract && {
      id: offer.contract.id,
      status: offer.contract.status,
      finalAmount: offer.contract.finalAmount,
      executionDate: dayjs(offer.contract.executionDate),
      createdAt: dayjs(offer.contract.createdAt),
      updatedAt: dayjs(offer.contract.updatedAt),
    } || null;
    this.createdAt = dayjs(offer.createdAt);
    this.updatedAt = dayjs(offer.updatedAt);
  }
}