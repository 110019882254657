import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import {PhotoGallery} from "../PhotoGallery";
import Button from '@mui/material/Button';
import {useRouter} from '@tanstack/react-router';
import {
  OrderItem,
  OrderItemPropsCheckboxConfig,
  OrderItemPropsCounterConfig,
  OrderItemPropsRadioConfig,
  OrderItemPropsSelectConfig
} from "@tarimli-mono/api/src/shared/order-items-types";
import {OrderContent} from "@tarimli-mono/api/src/shared/order-content";
import {AdminEditOrderLink, useIsAdminDashboard} from "../admin-elements.tsx";
import {ServiceNamesLabels} from "@tarimli-mono/api/src/shared/partner-service.ts";
import {ClientPartner} from "@tarimli-mono/api/src/shared/client-model/client-partner.ts";

const labels = {
  unpackingDate: 'תאריך פריקה וסידור',
  movingDate: 'תאריך הובלה',
  servicesTitle: 'השירותים המתבקשים',
  change: 'עריכה',
  'items': 'פריטים',
  'from-address': 'הבית שעוזבים',
  'to-address': 'הבית החדש',
  'moving-date': 'מתי עוברים',
  'rooms': 'חדרים',
  'floor': 'קומה',
  entrance: 'כניסה',
  'parking': 'חנייה למשאית',
  'lift': 'מעלית',
  'crane': 'מנוף',
  'there-is': 'יש',
  'there-isnt': 'אין',
  'apartment-has': `בדירה יש`,
  'need-to': 'יש צורך',
  'no-need-to': 'אין צורך',
  'floors': 'קומות',
  'one-floor': 'קומה אחת',
  'two-floors': 'שתי קומות',
  'more-floors': 'יותר מקומה אחת',
  gallery: 'גלריה',
  'missing': 'לא צוין',
  'plus-assembly': '+ הרכבה',
  'plus-disassembly': '+ פירוק',
  plusThrowAway: '+ פינוי לפח',
  packages: 'תכולה ארוזה והערות',
  bags: 'שקיות',
  suitcases: 'מזוודות',
  cases: 'תיקים',
  boxes: 'ארגזים',
  orderComments: 'הערות להובלה:',
  handymanComments: 'פירוט לשירותי הנדימן:',
}

export function V1_OrderSummary({orderContent, partner, toConfirm}: {
  orderContent: OrderContent,
  partner?: ClientPartner,
  toConfirm?: boolean
}) {
  const router = useRouter();
  const isAdminDashboard = useIsAdminDashboard();
  const disableEdit = !!partner || toConfirm || isAdminDashboard;
  const allowEdit = !disableEdit;

  const onClickChangeDepart = async () => {
    await router.navigate({
      to: `/order/${orderContent.orderId}`,
      resetScroll: true,
    });
    window.scrollTo({top: 0, behavior: 'smooth'})
  }
  const onClickChangeArrive = async () => {
    await router.navigate({
      to: `/order/${orderContent.orderId}/arrive`,
      resetScroll: true,
    });
    window.scrollTo({top: 0, behavior: 'smooth'})
  }
  const onClickChangeDate = async () => {
    await router.navigate({
      to: `/order/${orderContent.orderId}/date`,
      resetScroll: true,
    });
    window.scrollTo({top: 0, behavior: 'smooth'})
  }

  const onClickChangeItems = async () => {
    await router.navigate({
      to: `/order/${orderContent.orderId}/items`,
    });
    window.scrollTo({top: 0, behavior: 'smooth'})
  }

  const onClickChangeBoxes = async () => {
    await router.navigate({
      to: `/order/${orderContent.orderId}/boxes`,
    });
    window.scrollTo({top: 0, behavior: 'smooth'})
  }

  const entry = (title: string, value: string | null) => (
    <Stack gap={1} direction={'row'} sx={{alignItems: 'center'}}>
      <Typography sx={{fontSize: 14, opacity: 0.5}}>{title}</Typography>
      {value ? <Typography>{value}</Typography> :
        <Typography sx={{fontSize: 14, fontStyle: 'italic', opacity: 0.5}}>{labels['missing']}</Typography>}
    </Stack>
  )

  const itemRow = (item: OrderItem) => (
    <Stack gap={1} direction={'row'} sx={{alignItems: 'center'}}>
      <Box
        sx={{
          alignSelf: "flex-start",
          // backgroundColor: "#F5EDFF",
          border: "1px solid #F5EDFF",
          padding: 0.5,
          borderRadius: 0.5,
          aspectRatio: "1/1",
          height: "2em",
          textAlign: "center"
        }}>
        <Typography fontSize={14}>{item.quantity}</Typography>
      </Box>
      <Stack gap={0.5}>
        <Typography fontSize={14} fontWeight={400}>{item.title}</Typography>
        {(item.disassembly || item.assembly || item.throwAway) ?
          <Stack direction={"row"} gap={1}>
            {item.disassembly ? <Typography fontSize={12}>{labels['plus-disassembly']}</Typography> : null}
            {item.assembly ? <Typography fontSize={12}>{labels['plus-assembly']}</Typography> : null}
            {item.throwAway ? <Typography fontSize={12}>{labels.plusThrowAway}</Typography> : null}
          </Stack> : null
        }
        {
          item.itemProps.filter(p => !!p).map(({type, config, value}, itemPropIdx) => {
            switch (type) {
              case 'counter':
                const {label: counterLabel} = config as OrderItemPropsCounterConfig;
                return (
                  <Stack key={counterLabel} direction={'row'} gap={1}>
                    <Typography fontSize={12}>{counterLabel}:</Typography>
                    <Typography fontSize={12}>{value}</Typography>
                  </Stack>
                );
                break;
              case 'checkbox':
                if (value) {
                  const {label: checkboxLabel} = config as OrderItemPropsCheckboxConfig;
                  return (
                    <Stack key={checkboxLabel} direction={'row'} gap={1} sx={{alignItems: 'center'}}>
                      <CheckBoxIcon sx={{fontSize: 16, opacity: 0.5}}/>
                      <Typography fontSize={12}>{checkboxLabel}</Typography>
                    </Stack>
                  );
                } else {
                  return null;
                }
                break;
              case 'radio':
                const {label: radioLabel} = config as OrderItemPropsRadioConfig;
                return (
                  <Stack key={radioLabel} direction={'row'} gap={1}>
                    <Typography fontSize={12}>{radioLabel}:</Typography>
                    <Typography fontSize={12}>{value}</Typography>
                  </Stack>
                );
                break;
              case 'select':
                const {label: selectLabel} = config as OrderItemPropsSelectConfig;
                return (
                  <Stack key={selectLabel} direction={'row'} gap={1}>
                    <Typography fontSize={12}>{selectLabel}:</Typography>
                    <Typography fontSize={12}>{value}</Typography>
                  </Stack>
                );
                break;
            }
          })
        }
        {item.comments ? <Typography fontSize={12}>{item.comments}</Typography> : null}
        {
          item?.media?.length > 0 ?
            <PhotoGallery images={(item.media || []).map(([id, url]) => ({
              src: url,
              width: 100,
              height: 100,
              title: item.title,
              description: item.comments
            }))}/> : null
        }
      </Stack>
    </Stack>
  );

  const boxesItemRow = (label: string, count: string) => (
    <Stack gap={1} direction={'row'} sx={{alignItems: 'center'}}>
      <Box
        sx={{
          alignSelf: "flex-start",
          // backgroundColor: "#F5EDFF",
          border: "1px solid #F5EDFF",
          padding: 0.5,
          borderRadius: 0.5,
          aspectRatio: "1/1",
          height: "2em",
          textAlign: "center"
        }}>
        <Typography fontSize={14}>{count}</Typography>
      </Box>
      <Stack gap={0.5}>
        <Typography fontSize={14} fontWeight={400}>{label}</Typography>
      </Stack>
    </Stack>
  );

  return (
    <Stack gap={2} sx={{
      padding: 2,
      borderRadius: 1,
      overflow: "hidden",
      backgroundColor: "white"
    }}>
      {orderContent.hasSomeResolvedService(['moving', 'smallMoving']) && (partner ? partner.hasSomeService(['moving', 'smallMoving']) : true) ?
        <Stack gap={2}>
          <RequestedServices orderContent={orderContent}/>

          <Divider sx={{
            margin: 0,
          }}/>

          <Stack direction={'row'} gap={1} alignItems={'flex-start'}>
            <Stack sx={{flexGrow: 1}}>
              <Typography sx={{fontSize: 16}}>{labels.movingDate}</Typography>
              <Typography sx={{fontSize: 18}}>{orderContent.movingDateFormatted}</Typography>
            </Stack>
            {allowEdit ? <Button onClick={onClickChangeDate}>{labels['change']}</Button> : null}
            <AdminEditOrderLink section={'date'}/>
          </Stack>

          <Divider sx={{
            margin: 0,
          }}/>

          <Stack direction={'row'} gap={1} alignItems={'flex-start'}>
            <Stack sx={{flexGrow: 1}}>
              <Typography sx={{fontSize: 16}}>{labels['from-address']}</Typography>
              <Typography sx={{fontSize: 18}}>{orderContent.strDepartAddress}</Typography>
            </Stack>
            {allowEdit ? <Button onClick={onClickChangeDepart}>{labels['change']}</Button> : null}
            <AdminEditOrderLink section={'depart'}/>
          </Stack>

          <Stack gap={0.5}>
            {entry(orderContent.entries.departFloor.label, orderContent.entries.departFloor.toSummaryDisplay(orderContent.entries))}
            {orderContent.isLargeMoving ? entry(orderContent.entries.departRooms.label, orderContent.entries.departRooms.toSummaryDisplay(orderContent.entries)) : null}
            {entry(orderContent.entries.departParking.label, orderContent.has('departParking') ? orderContent.entries.departParking.toSummaryDisplay(orderContent.entries) : null)}
            {['no', 'maybe'].includes(orderContent.entries.departParking.value) && entry(orderContent.entries.departParkingComments.label, orderContent.entries.departParkingComments.toSummaryDisplay(orderContent.entries))}
            {entry(orderContent.entries.departLift.label, orderContent.has('departLift') ? orderContent.entries.departLift.toSummaryDisplay(orderContent.entries) : null)}
            {entry(orderContent.entries.departCrane.label, orderContent.has('departCrane') ? orderContent.entries.departCrane.toSummaryDisplay(orderContent.entries) : null)}
            {['yes', 'maybe'].includes(orderContent.entries.departCrane.value) && entry(orderContent.entries.departCraneComments.label, orderContent.entries.departCraneComments.toSummaryDisplay(orderContent.entries))}
            {orderContent.isLargeMoving ? orderContent.hasDepartExternalSpace ? entry(orderContent.entries.departExternalSpace.label, orderContent.entries.departExternalSpace.toSummaryDisplay(orderContent.entries)) : null : null}
            {orderContent.isLargeMoving ? entry(orderContent.entries.departHouseFloorsNum.label, orderContent.entries.departHouseFloorsNum.toSummaryDisplay(orderContent.entries)) : null}
          </Stack>

          <Divider sx={{
            margin: 0,
          }}/>

          <Stack direction={'row'} gap={1} alignItems={'flex-start'}>
            <Stack sx={{flexGrow: 1}}>
              <Typography sx={{fontSize: 16}}>{labels['to-address']}</Typography>
              <Typography sx={{fontSize: 22}}>{orderContent.strArriveAddress}</Typography>
            </Stack>
            {allowEdit ? <Button onClick={onClickChangeArrive}>{labels['change']}</Button> : null}
            <AdminEditOrderLink section={'arrive'}/>
          </Stack>

          <Stack gap={0.5}>
            {entry(orderContent.entries.arriveFloor.label, orderContent.entries.arriveFloor.toSummaryDisplay(orderContent.entries))}
            {entry(orderContent.entries.arriveParking.label, orderContent.has('arriveParking') ? orderContent.entries.arriveParking.toSummaryDisplay(orderContent.entries) : null)}
            {['no', 'maybe'].includes(orderContent.entries.arriveParking.value) && entry(orderContent.entries.arriveParkingComments.label, orderContent.entries.arriveParkingComments.toSummaryDisplay(orderContent.entries))}
            {entry(orderContent.entries.arriveLift.label, orderContent.has('arriveLift') ? orderContent.entries.arriveLift.toSummaryDisplay(orderContent.entries) : null)}
            {entry(orderContent.entries.arriveCrane.label, orderContent.has('arriveCrane') ? orderContent.entries.arriveCrane.toSummaryDisplay(orderContent.entries) : null)}
            {['yes', 'maybe'].includes(orderContent.entries.arriveCrane.value) && entry(orderContent.entries.arriveCraneComments.label, orderContent.entries.arriveCraneComments.toSummaryDisplay(orderContent.entries))}
            {orderContent.isLargeMoving ? entry(orderContent.entries.arriveHouseFloorsNum.label, orderContent.entries.arriveHouseFloorsNum.toSummaryDisplay(orderContent.entries)) : null}
          </Stack>

          <Divider sx={{
            margin: 0,
          }}/>

          <Stack direction={'row'} gap={1} alignItems={'flex-start'}>
            <Stack sx={{flexGrow: 1}}>
              <Typography sx={{fontSize: 16}}>{labels['items']}</Typography>
            </Stack>
            {allowEdit ? <Button onClick={onClickChangeItems}>{labels['change']}</Button> : null}
            <AdminEditOrderLink section={'items'}/>
          </Stack>

          {Object.entries(orderContent.itemsByCategoryName).map(([categoryName, it]) => (
            <Stack key={categoryName} gap={2}>
              <Typography sx={{fontSize: 16, fontWeight: 600}}>{categoryName}</Typography>
              {it.map(item => <Box key={item.id}>{itemRow(item)}</Box>)}
            </Stack>
          ))}

          <Divider/>

          <Stack gap={2}>
            <Stack direction={'row'} gap={1} alignItems={'center'}>
              <Stack sx={{flexGrow: 1}}>
                <Typography sx={{fontSize: 16}}>{labels['packages']}</Typography>
              </Stack>
              {allowEdit ? <Button onClick={onClickChangeBoxes}>{labels['change']}</Button> : null}
              <AdminEditOrderLink section={'boxes'}/>
            </Stack>
            {entry(orderContent.entries.orderComments.label, orderContent.has('orderComments') ? orderContent.entries.orderComments.toSummaryDisplay(orderContent.entries) : null)}
            {orderContent.isSmallMoving ? boxesItemRow(orderContent.entries.smallMovingBoxesCount.label, orderContent.entries.smallMovingBoxesCount.toSummaryDisplay(orderContent.entries)) : null}
            {orderContent.isLargeMoving ? boxesItemRow(orderContent.entries.bagsCount.label, orderContent.entries.bagsCount.toSummaryDisplay(orderContent.entries)) : null}
            {orderContent.isLargeMoving ? boxesItemRow(orderContent.entries.suitcasesCount.label, orderContent.entries.suitcasesCount.toSummaryDisplay(orderContent.entries)) : null}
            {orderContent.isLargeMoving ? boxesItemRow(orderContent.entries.sacksCount.label, orderContent.entries.sacksCount.toSummaryDisplay(orderContent.entries)) : null}
            {orderContent.isLargeMoving ? boxesItemRow(orderContent.entries.boxesCount.label, orderContent.entries.boxesCount.toSummaryDisplay(orderContent.entries)) : null}
            {orderContent.isLargeMoving ? boxesItemRow(orderContent.entries.builderBagsCount.label, orderContent.entries.builderBagsCount.toSummaryDisplay(orderContent.entries)) : null}
          </Stack>
        </Stack>
        : null}
      {orderContent.hasResolvedService('unpacking') && (partner ? partner.hasService('unpacking') : true) ?
        <Stack gap={2}>
          <Stack direction={'row'} gap={1} alignItems={'flex-start'}>
            <Stack sx={{flexGrow: 1}}>
              <Typography sx={{fontSize: 16}}>{labels.unpackingDate}</Typography>
              <Typography sx={{fontSize: 18}}>{orderContent.unpackingDateFormatted}</Typography>
            </Stack>
            <AdminEditOrderLink section={'services'}/>
          </Stack>
          {entry(orderContent.entries.unpackingRooms.label, orderContent.entries.unpackingRooms.toSummaryDisplay(orderContent.entries))}
          {boxesItemRow(orderContent.entries.unpackingClosetsCount.label, orderContent.entries.unpackingClosetsCount.toSummaryDisplay(orderContent.entries))}
          {boxesItemRow(orderContent.entries.unpackingKitchenCount.label, orderContent.entries.unpackingKitchenCount.toSummaryDisplay(orderContent.entries))}
          {boxesItemRow(orderContent.entries.unpackingBathroomCount.label, orderContent.entries.unpackingBathroomCount.toSummaryDisplay(orderContent.entries))}
          {boxesItemRow(orderContent.entries.unpackingBoxesCount.label, orderContent.entries.unpackingBoxesCount.toSummaryDisplay(orderContent.entries))}
          {boxesItemRow(orderContent.entries.unpackingBagsCount.label, orderContent.entries.unpackingBagsCount.toSummaryDisplay(orderContent.entries))}
          {boxesItemRow(orderContent.entries.unpackingSuitcasesCount.label, orderContent.entries.unpackingSuitcasesCount.toSummaryDisplay(orderContent.entries))}
          {boxesItemRow(orderContent.entries.unpackingSacksCount.label, orderContent.entries.unpackingSacksCount.toSummaryDisplay(orderContent.entries))}
          {boxesItemRow(orderContent.entries.unpackingBuilderBagsCount.label, orderContent.entries.unpackingBuilderBagsCount.toSummaryDisplay(orderContent.entries))}
        </Stack>
        : null}
      {orderContent.hasResolvedService('handyman') && (partner ? partner.hasService('handyman') : true) ?
        <Stack gap={2}>
          <Typography variant={'h6'}>שירותי הנדימן</Typography>
          <Stack direction={'row'} gap={1} alignItems={'flex-start'}>
            <Stack sx={{flexGrow: 1}}>
              <Typography sx={{fontSize: 16}}>{labels.movingDate}</Typography>
              <Typography sx={{fontSize: 18}}>{orderContent.movingDateFormatted}</Typography>
            </Stack>
            {allowEdit ? <Button onClick={onClickChangeDate}>{labels['change']}</Button> : null}
            <AdminEditOrderLink section={'date'}/>
          </Stack>

          <Divider sx={{
            margin: 0,
          }}/>

          <Stack direction={'row'} gap={1} alignItems={'flex-start'}>
            <Stack sx={{flexGrow: 1}}>
              <Typography sx={{fontSize: 16}}>{labels['to-address']}</Typography>
              <Typography sx={{fontSize: 22}}>{orderContent.strArriveAddress}</Typography>
            </Stack>
            {allowEdit ? <Button onClick={onClickChangeArrive}>{labels['change']}</Button> : null}
            <AdminEditOrderLink section={'arrive'}/>
          </Stack>

          <Divider sx={{
            margin: 0,
          }}/>
          <Typography variant={'subtitle1'}>{labels.handymanComments}</Typography>
          {entry(null, orderContent.has('handymanComments') ? orderContent.entries.handymanComments.toSummaryDisplay(orderContent.entries) : null)}
          
        </Stack>
        : null}

      {orderContent.hasResolvedService('locksmith') && (partner ? partner.hasService('locksmith') : true) ?
        <Stack gap={2}>
          <Typography variant={'h6'}>שירותי מנעולן</Typography>
          <Stack direction={'row'} gap={1} alignItems={'flex-start'}>
            <Stack sx={{flexGrow: 1}}>
              <Typography sx={{fontSize: 16}}>{labels.movingDate}</Typography>
              <Typography sx={{fontSize: 18}}>{orderContent.movingDateFormatted}</Typography>
            </Stack>
            {allowEdit ? <Button onClick={onClickChangeDate}>{labels['change']}</Button> : null}
            <AdminEditOrderLink section={'date'}/>
          </Stack>

          <Divider sx={{
            margin: 0,
          }}/>

          <Stack direction={'row'} gap={1} alignItems={'flex-start'}>
            <Stack sx={{flexGrow: 1}}>
              <Typography sx={{fontSize: 16}}>{labels['to-address']}</Typography>
              <Typography sx={{fontSize: 22}}>{orderContent.strArriveAddress}</Typography>
            </Stack>
            {allowEdit ? <Button onClick={onClickChangeArrive}>{labels['change']}</Button> : null}
            <AdminEditOrderLink section={'arrive'}/>
          </Stack>
          
        </Stack>
        : null}
    </Stack>
  )
}

const RequestedServices = ({orderContent}: { orderContent: OrderContent }) => {
  return (
    <Stack>
      <Typography sx={{fontSize: 22, fontWeight: 500}}>{labels.servicesTitle}</Typography>
      {orderContent.isLargeMoving ? <Stack direction={'row'} gap={1} alignItems={'center'}>
        <CheckBoxIcon sx={{fontSize: 16}}/>
        <Typography>{ServiceNamesLabels['moving']}</Typography>
      </Stack> : null}
      {orderContent.isSmallMoving ? <Stack direction={'row'} gap={1} alignItems={'center'}>
        <CheckBoxIcon sx={{fontSize: 16}}/>
        <Typography>{ServiceNamesLabels['smallMoving']}</Typography>
      </Stack> : null}
      <Stack direction={'row'} gap={1} alignItems={'center'}>
        {orderContent.hasResolvedService('crane') ? <CheckBoxIcon sx={{fontSize: 16}}/> :
          <CheckBoxOutlineBlankIcon sx={{fontSize: 16}}/>}
        <Typography>{ServiceNamesLabels['crane']}</Typography>
      </Stack>
      {orderContent.isLargeMoving ? <>
        <Stack direction={'row'} gap={1} alignItems={'center'}>
          {orderContent.hasResolvedService('packingMaterials') ? <CheckBoxIcon sx={{fontSize: 16}}/> :
            <CheckBoxOutlineBlankIcon sx={{fontSize: 16}}/>}
          <Typography>{ServiceNamesLabels['packingMaterials']}</Typography>
        </Stack>
        <Stack direction={'row'} gap={1} alignItems={'center'}>
          {orderContent.hasResolvedService('packing') ? <CheckBoxIcon sx={{fontSize: 16}}/> :
            <CheckBoxOutlineBlankIcon sx={{fontSize: 16}}/>}
          <Typography>{ServiceNamesLabels['packing']}</Typography>
        </Stack>
      </> : null}
    </Stack>
  );
}
