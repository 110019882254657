import dayjs from 'dayjs';
import {COrderStatus, ICContractOfCustomer, ICCustomerOwnOrder, ICOrderForPartner} from "../client-types";
import {OrderContent} from "../order-content";
import {OrderRating} from "../rating";
import {ClientOfferForCustomer, ClientPartnerOwnOffer} from "./client-offer";
import {ClientCustomer} from "./client-customer";
import {ServiceGroupName} from "../partner-service";

export class ClientCustomerContract {
  id: ICContractOfCustomer['id'];
  offer: ClientOfferForCustomer;
  orderId: ICContractOfCustomer['orderId'];
  status: ICContractOfCustomer['status'];
  finalAmount: ICContractOfCustomer['finalAmount'] | null;
  executionDate: dayjs.Dayjs | null;
  review: OrderRating | null;
  createdAt: dayjs.Dayjs;
  updatedAt: dayjs.Dayjs;

  constructor(contract: ICContractOfCustomer, offer: ClientOfferForCustomer) {
    this.id = contract.id;
    this.offer = offer;
    this.orderId = contract.orderId;
    this.status = contract.status;
    this.finalAmount = contract.finalAmount;
    this.executionDate = contract.executionDate && dayjs(contract.executionDate) || null;
    this.review = contract.review && new OrderRating(contract.review) || null;
    this.createdAt = dayjs(contract.createdAt);
    this.updatedAt = dayjs(contract.updatedAt);
  }
}

export class ClientCustomerOwnOrder {
  id: ICCustomerOwnOrder['id'];
  customer: ClientCustomer;
  childrenIds?: ICCustomerOwnOrder['childrenIds'];
  parentId?: ICCustomerOwnOrder['parentId'];
  customerId: ICCustomerOwnOrder['customerId'];
  status: ICCustomerOwnOrder['status'];
  serviceNames: ICCustomerOwnOrder['serviceNames'];
  content: OrderContent;
  offers: ClientOfferForCustomer[];
  contracts: ClientCustomerContract[];
  review: OrderRating | null;
  paymentData?: {
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    paymentsNum: number;
  };
  finalAmount?: number;
  readonly createdAt: dayjs.Dayjs;
  readonly updatedAt: dayjs.Dayjs;

  constructor(order: ICCustomerOwnOrder, customer: ClientCustomer) {
    this.id = order.id;
    this.customer = customer;
    this.childrenIds = order.childrenIds;
    this.parentId = order.parentId;
    this.customerId = order.customerId;
    this.status = order.status;
    this.serviceNames = order.serviceNames;
    if (!Array.isArray(this.serviceNames) || this.serviceNames.length === 0) {
      this.serviceNames = ['moving'];
    }
    this.content = new OrderContent(order.id, order.content, order.items);
    this.offers = order.offers.map(offer => new ClientOfferForCustomer(offer));
    this.contracts = order.contracts.map(contract => new ClientCustomerContract(contract, this.offers.find(offer => offer.id === contract.offerId) || null));
    this.review = order.review && new OrderRating(order.review) || null;
    this.paymentData = order.paymentData;
    this.finalAmount = order.finalAmount;
    this.createdAt = dayjs(order.createdAt);
    this.updatedAt = dayjs(order.updatedAt);
  }
  
  getOffersForServiceGroup(groupName: ServiceGroupName): ClientOfferForCustomer[] {
    return this.offers.filter(offer => offer.serviceGroup === groupName);
  }
  
  get isEdit() {
    return this.status === 'draft' && ['open', 'closed'].includes(this.parent?.status);
  }

  get hasChildren(): boolean {
    return this.childrenIds && this.childrenIds.length > 0;
  }

  get hasSiblings(): boolean {
    return this.siblings.length > 0;
  }

  get parent(): ClientCustomerOwnOrder | null {
    if (!this.parentId) {
      return null;
    }
    return this.customer.orders.find(order => order.id === this.parentId) || null;
  }

  get siblings(): ClientCustomerOwnOrder[] {
    if (!this.parentId) {
      return [];
    }
    return this.customer.orders.filter(order => order.parentId === this.parentId && order.id !== this.id);
  }

  get children(): ClientCustomerOwnOrder[] {
    return this.customer.orders.filter(order => this.childrenIds?.includes(order.id));
  }

  get isChild(): boolean {
    return !!this.parent;
  }
}

export class ClientOrderForPartner {
  id: number;
  status: COrderStatus;
  content: OrderContent;
  offers: ClientPartnerOwnOffer[];
  declinedAt: dayjs.Dayjs | null;
  declineReason: string | null;
  submittedAt: dayjs.Dayjs | null;
  createdAt: dayjs.Dayjs;
  updatedAt: dayjs.Dayjs;

  constructor(order: ICOrderForPartner) {
    this.id = order.id;
    this.status = order.status;
    this.content = new OrderContent(order.id, order.content, order.items);
    this.offers = order.offers.map(o => new ClientPartnerOwnOffer(o));
    this.declinedAt = order.declinedAt ? dayjs(order.declinedAt) : null;
    this.declineReason = order.declineReason || null;
    this.submittedAt = order.submittedAt ? dayjs(order.submittedAt) : null;
    this.createdAt = dayjs(order.createdAt);
    this.updatedAt = dayjs(order.updatedAt);
  }

  get isDeclined(): boolean {
    return !!this.declinedAt;
  }

  get isOpenForOffers(): boolean {
    return this.status === 'open' && !this.declinedAt && !this.hasMyOffers;
  }

  get acceptedOffers(): ClientPartnerOwnOffer[] {
    return this.offers.filter(offer => offer.status === 'accepted');
  }

  get hasAcceptedOffers(): boolean {
    return this.acceptedOffers.length > 0;
  }

  get submittedOffers(): ClientPartnerOwnOffer[] {
    return this.offers.filter(offer => offer.status === 'submitted');
  }

  get hasSubmittedOffers(): boolean {
    return this.submittedOffers.length > 0;
  }

  get myOffers(): ClientPartnerOwnOffer[] {
    return [...this.acceptedOffers, ...this.submittedOffers];
  }

  get hasMyOffers(): boolean {
    return this.myOffers.length > 0;
  }

  get cancelledOffers(): ClientPartnerOwnOffer[] {
    return this.offers.filter(offer => offer.status === 'cancelled');
  }

  get hasCancelledOffers(): boolean {
    return this.cancelledOffers.length > 0;
  }

}
