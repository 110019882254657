import {PostHog, PostHogProvider as Provider, usePostHog} from "posthog-js/react";
import {useEffect, useState} from "react";
import posthog, { CapturedNetworkRequest } from "posthog-js";

interface Props {
  children: any;
}

export const PostHogProvider = ({children}: Props) => {
  const [postHogClient, setPostHogClient] = useState<PostHog | undefined>();

  useEffect(() => {
    if (postHogClient) {
      return;
    }

    posthog.init(import.meta.env.VITE_POSTHOG_KEY, {
      api_host: import.meta.env.VITE_POSTHOG_HOST,
      capture_pageview: true,
      capture_pageleave: true,
      capture_dead_clicks: true,
      person_profiles: 'always',
      enable_heatmaps: true,
      session_recording: {
        maskCapturedNetworkRequestFn: (request: CapturedNetworkRequest) => {
          return request;
        },
        maskAllInputs: false,
        maskInputOptions: {
          password: true,
        },
        maskInputFn: (text: string) => text,
      },
    });
    setPostHogClient(posthog);
  }, []);

  return (
    <Provider client={postHogClient}>
      {children}
    </Provider>
  );
};
