import {createFileRoute, Navigate} from '@tanstack/react-router'
import {useAuth} from "../services/auth.tsx";

export const Route = createFileRoute('/')({
  component: Index,
})

function Index() {
  const auth = useAuth();
  if (auth.isPartner) {
    return <Navigate to="/partner"/>
  } else if (auth.isCustomer) {
    if (auth.customer.isRegistered) {
      return <Navigate to="/orders"/>
    } else {
      return <Navigate to="/register"/>
    }
  } else {
    return <Navigate to="/login"/>
  }
}
