/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as PartnerLoginImport } from './routes/partner-login'
import { Route as LoginImport } from './routes/login'
import { Route as LinkImport } from './routes/link'
import { Route as AuthImport } from './routes/_auth'
import { Route as IndexImport } from './routes/index'
import { Route as AuthPartnerIndexImport } from './routes/_auth/partner_/index'
import { Route as AuthPartnerTermsConfirmedImport } from './routes/_auth/partner_/terms-confirmed'
import { Route as AuthPartnerProfileImport } from './routes/_auth/partner_/profile'
import { Route as AuthPartnerOrdersImport } from './routes/_auth/partner_/orders'
import { Route as AuthPartnerOrderOrderIdImport } from './routes/_auth/partner_/order_.$orderId'

// Create/Update Routes

const PartnerLoginRoute = PartnerLoginImport.update({
  id: '/partner-login',
  path: '/partner-login',
  getParentRoute: () => rootRoute,
} as any)

const LoginRoute = LoginImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => rootRoute,
} as any)

const LinkRoute = LinkImport.update({
  id: '/link',
  path: '/link',
  getParentRoute: () => rootRoute,
} as any)

const AuthRoute = AuthImport.update({
  id: '/_auth',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const AuthPartnerIndexRoute = AuthPartnerIndexImport.update({
  id: '/partner_/',
  path: '/partner/',
  getParentRoute: () => AuthRoute,
} as any)

const AuthPartnerTermsConfirmedRoute = AuthPartnerTermsConfirmedImport.update({
  id: '/partner_/terms-confirmed',
  path: '/partner/terms-confirmed',
  getParentRoute: () => AuthRoute,
} as any)

const AuthPartnerProfileRoute = AuthPartnerProfileImport.update({
  id: '/partner_/profile',
  path: '/partner/profile',
  getParentRoute: () => AuthRoute,
} as any)

const AuthPartnerOrdersRoute = AuthPartnerOrdersImport.update({
  id: '/partner_/orders',
  path: '/partner/orders',
  getParentRoute: () => AuthRoute,
} as any)

const AuthPartnerOrderOrderIdRoute = AuthPartnerOrderOrderIdImport.update({
  id: '/partner_/order_/$orderId',
  path: '/partner/order/$orderId',
  getParentRoute: () => AuthRoute,
} as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/_auth': {
      id: '/_auth'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthImport
      parentRoute: typeof rootRoute
    }
    '/link': {
      id: '/link'
      path: '/link'
      fullPath: '/link'
      preLoaderRoute: typeof LinkImport
      parentRoute: typeof rootRoute
    }
    '/login': {
      id: '/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof LoginImport
      parentRoute: typeof rootRoute
    }
    '/partner-login': {
      id: '/partner-login'
      path: '/partner-login'
      fullPath: '/partner-login'
      preLoaderRoute: typeof PartnerLoginImport
      parentRoute: typeof rootRoute
    }
    '/_auth/partner_/orders': {
      id: '/_auth/partner_/orders'
      path: '/partner/orders'
      fullPath: '/partner/orders'
      preLoaderRoute: typeof AuthPartnerOrdersImport
      parentRoute: typeof AuthImport
    }
    '/_auth/partner_/profile': {
      id: '/_auth/partner_/profile'
      path: '/partner/profile'
      fullPath: '/partner/profile'
      preLoaderRoute: typeof AuthPartnerProfileImport
      parentRoute: typeof AuthImport
    }
    '/_auth/partner_/terms-confirmed': {
      id: '/_auth/partner_/terms-confirmed'
      path: '/partner/terms-confirmed'
      fullPath: '/partner/terms-confirmed'
      preLoaderRoute: typeof AuthPartnerTermsConfirmedImport
      parentRoute: typeof AuthImport
    }
    '/_auth/partner_/': {
      id: '/_auth/partner_/'
      path: '/partner'
      fullPath: '/partner'
      preLoaderRoute: typeof AuthPartnerIndexImport
      parentRoute: typeof AuthImport
    }
    '/_auth/partner_/order_/$orderId': {
      id: '/_auth/partner_/order_/$orderId'
      path: '/partner/order/$orderId'
      fullPath: '/partner/order/$orderId'
      preLoaderRoute: typeof AuthPartnerOrderOrderIdImport
      parentRoute: typeof AuthImport
    }
  }
}

// Create and export the route tree

interface AuthRouteChildren {
  AuthPartnerOrdersRoute: typeof AuthPartnerOrdersRoute
  AuthPartnerProfileRoute: typeof AuthPartnerProfileRoute
  AuthPartnerTermsConfirmedRoute: typeof AuthPartnerTermsConfirmedRoute
  AuthPartnerIndexRoute: typeof AuthPartnerIndexRoute
  AuthPartnerOrderOrderIdRoute: typeof AuthPartnerOrderOrderIdRoute
}

const AuthRouteChildren: AuthRouteChildren = {
  AuthPartnerOrdersRoute: AuthPartnerOrdersRoute,
  AuthPartnerProfileRoute: AuthPartnerProfileRoute,
  AuthPartnerTermsConfirmedRoute: AuthPartnerTermsConfirmedRoute,
  AuthPartnerIndexRoute: AuthPartnerIndexRoute,
  AuthPartnerOrderOrderIdRoute: AuthPartnerOrderOrderIdRoute,
}

const AuthRouteWithChildren = AuthRoute._addFileChildren(AuthRouteChildren)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '': typeof AuthRouteWithChildren
  '/link': typeof LinkRoute
  '/login': typeof LoginRoute
  '/partner-login': typeof PartnerLoginRoute
  '/partner/orders': typeof AuthPartnerOrdersRoute
  '/partner/profile': typeof AuthPartnerProfileRoute
  '/partner/terms-confirmed': typeof AuthPartnerTermsConfirmedRoute
  '/partner': typeof AuthPartnerIndexRoute
  '/partner/order/$orderId': typeof AuthPartnerOrderOrderIdRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '': typeof AuthRouteWithChildren
  '/link': typeof LinkRoute
  '/login': typeof LoginRoute
  '/partner-login': typeof PartnerLoginRoute
  '/partner/orders': typeof AuthPartnerOrdersRoute
  '/partner/profile': typeof AuthPartnerProfileRoute
  '/partner/terms-confirmed': typeof AuthPartnerTermsConfirmedRoute
  '/partner': typeof AuthPartnerIndexRoute
  '/partner/order/$orderId': typeof AuthPartnerOrderOrderIdRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/_auth': typeof AuthRouteWithChildren
  '/link': typeof LinkRoute
  '/login': typeof LoginRoute
  '/partner-login': typeof PartnerLoginRoute
  '/_auth/partner_/orders': typeof AuthPartnerOrdersRoute
  '/_auth/partner_/profile': typeof AuthPartnerProfileRoute
  '/_auth/partner_/terms-confirmed': typeof AuthPartnerTermsConfirmedRoute
  '/_auth/partner_/': typeof AuthPartnerIndexRoute
  '/_auth/partner_/order_/$orderId': typeof AuthPartnerOrderOrderIdRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | ''
    | '/link'
    | '/login'
    | '/partner-login'
    | '/partner/orders'
    | '/partner/profile'
    | '/partner/terms-confirmed'
    | '/partner'
    | '/partner/order/$orderId'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | ''
    | '/link'
    | '/login'
    | '/partner-login'
    | '/partner/orders'
    | '/partner/profile'
    | '/partner/terms-confirmed'
    | '/partner'
    | '/partner/order/$orderId'
  id:
    | '__root__'
    | '/'
    | '/_auth'
    | '/link'
    | '/login'
    | '/partner-login'
    | '/_auth/partner_/orders'
    | '/_auth/partner_/profile'
    | '/_auth/partner_/terms-confirmed'
    | '/_auth/partner_/'
    | '/_auth/partner_/order_/$orderId'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  AuthRoute: typeof AuthRouteWithChildren
  LinkRoute: typeof LinkRoute
  LoginRoute: typeof LoginRoute
  PartnerLoginRoute: typeof PartnerLoginRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  AuthRoute: AuthRouteWithChildren,
  LinkRoute: LinkRoute,
  LoginRoute: LoginRoute,
  PartnerLoginRoute: PartnerLoginRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/_auth",
        "/link",
        "/login",
        "/partner-login"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/_auth": {
      "filePath": "_auth.tsx",
      "children": [
        "/_auth/partner_/orders",
        "/_auth/partner_/profile",
        "/_auth/partner_/terms-confirmed",
        "/_auth/partner_/",
        "/_auth/partner_/order_/$orderId"
      ]
    },
    "/link": {
      "filePath": "link.tsx"
    },
    "/login": {
      "filePath": "login.tsx"
    },
    "/partner-login": {
      "filePath": "partner-login.tsx"
    },
    "/_auth/partner_/orders": {
      "filePath": "_auth/partner_/orders.tsx",
      "parent": "/_auth"
    },
    "/_auth/partner_/profile": {
      "filePath": "_auth/partner_/profile.tsx",
      "parent": "/_auth"
    },
    "/_auth/partner_/terms-confirmed": {
      "filePath": "_auth/partner_/terms-confirmed.tsx",
      "parent": "/_auth"
    },
    "/_auth/partner_/": {
      "filePath": "_auth/partner_/index.tsx",
      "parent": "/_auth"
    },
    "/_auth/partner_/order_/$orderId": {
      "filePath": "_auth/partner_/order_.$orderId.tsx",
      "parent": "/_auth"
    }
  }
}
ROUTE_MANIFEST_END */
