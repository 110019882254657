import {FormAddress} from './client-types';
import {
  OrderContentEntry,
  OrderEntryAddress,
  OrderEntryDay,
  OrderEntryMultiselect,
  OrderEntryNumber,
  OrderEntrySelect,
  OrderEntryText, OrderFormEntries
} from './order-entries-types';
import {sanitizeMessageTextSpaces} from './util';
import {rules} from './rules';

export type ServiceGroupsContentValues = {
  moving: {
    departAddress?: FormAddress,
    departFloor?: string,
    departRooms?: string,
    departParking?: string,
    departParkingComments?: string,
    departLift?: string,
    departLiftComments?: string,
    departCrane?: string,
    departCraneComments?: string,
    departExternalSpace?: string[],
    departBalconyCount?: string,
    departHouseStructure?: string,
    departHouseFloorsNum?: string,
    arriveAddress?: FormAddress,
    arriveFloor?: string,
    arriveParking?: string,
    arriveParkingComments?: string,
    arriveLift?: string,
    arriveCrane?: string,
    arriveCraneComments?: string,
    arriveHouseStructure?: string,
    arriveHouseFloorsNum?: string,
    dateDay?: string,
    dateDayHours?: string[],
    bagsCount?: number,
    suitcasesCount?: number,
    sacksCount?: number,
    builderBagsCount?: number,
    boxesCount?: number,
    orderComments?: string,
    packingMaterials?: string,
    packingService?: string,
    handymanService?: string,
    handymanComments?: string,
    locksmithService?: string,
  },
  unpacking: {
    departAddress?: FormAddress,
    arriveAddress?: FormAddress,
    unpackingService?: string,
    unpackingDateDay?: string,
    unpackingRooms?: string,
    unpackingClosetsCount?: number,
    unpackingKitchenCount?: number,
    unpackingBathroomCount?: number,
    unpackingBoxesCount?: number,
    unpackingBagsCount?: number,
    unpackingBuilderBagsCount?: number,
    unpackingSuitcasesCount?: number,
    unpackingSacksCount?: number,
    unpackingHasBoxes?: string,
    unpackingHasBags?: string,
    unpackingHasSuitcases?: string,
    unpackingHasSacks?: string,
    unpackingHasBuilderBags?: string,
  },
  smallMoving: {
    departAddress?: FormAddress,
    departFloor?: string,
    departParking?: string,
    departParkingComments?: string,
    departLift?: string,
    departLiftComments?: string,
    departCrane?: string,
    departCraneComments?: string,
    arriveAddress?: FormAddress,
    arriveFloor?: string,
    arriveParking?: string,
    arriveParkingComments?: string,
    arriveLift?: string,
    arriveCrane?: string,
    arriveCraneComments?: string,
    dateDay?: string,
    orderComments?: string,
    smallMovingBoxesCount?: number,
  }
}

export type OrderContentValues =
  ServiceGroupsContentValues['moving']
  & ServiceGroupsContentValues['unpacking']
  & ServiceGroupsContentValues['smallMoving']
  & {
  orderServiceGroup?: string,
  _version?: string,
  pdfFile?: string,
  firstName?: string,
  lastName?: string,
  additionalOffers?: string[],
  consentTextMessages?: string,
  consentPhoneCalls?: string,
  phone?: string,
}
export type OrderContentNames = keyof OrderContentValues;

const entryWithCommentsValueDisplay = (value: string, allOptions: OrderContentEntry<any>['options'], optionValuesForComments: string[], commentsValueDisplay?: string) => {
  let text = allOptions.find(o => o.value === value)?.label || '-';
  if (optionValuesForComments.includes(value)) {
    text = `${text} (${commentsValueDisplay || '-'})`;
  }
  text = sanitizeMessageTextSpaces(text);
  return text;
};

export class OrderPhoneField extends OrderEntryText {
  constructor(value: string) {
    super({
      name: 'phone',
      value,
      defaultValue: '',
      formLabel: 'טלפון',
      label: 'טלפון',
      maxLen: 30,
    });
  }

  validForSubmission(siblingEntries: OrderFormEntries): boolean {
    return this.isValid();
  }
}

export class FirstNameField extends OrderEntryText {
  constructor(value: string) {
    super({
      name: 'firstName',
      value,
      defaultValue: '',
      formLabel: 'שם פרטי',
      label: 'שם פרטי',
      formErrorMessageRequired: 'נא להזין שם פרטי',
      formErrorMessageMaxLen: 'לא יותר מ-30 תווים בבקשה',
      maxLen: 30,
    });
  }

  validForSubmission(siblingEntries: OrderFormEntries): boolean {
    return this.isValid();
  }
}

export class LastNameField extends OrderEntryText {
  constructor(value: string) {
    super({
      name: 'lastName',
      value,
      defaultValue: '',
      formLabel: 'שם משפחה',
      label: 'שם משפחה',
      formErrorMessageRequired: 'נא להזין שם משפחה',
      formErrorMessageMaxLen: 'לא יותר מ-30 תווים בבקשה',
      maxLen: 30,
    });
  }

  validForSubmission(siblingEntries: OrderFormEntries): boolean {
    return this.isValid();
  }
}

export class DepartAddress extends OrderEntryAddress {
  constructor(value: FormAddress) {
    super({
      name: 'depart.address',
      value,
      formLabel: 'כתובת מלאה',
      label: 'מאיפה',
      formErrorMessageRequired: 'נא לבחור כתובת מלאה',
    });
  }

  validForSubmission(siblingEntries: OrderFormEntries): boolean {
    return this.isValid();
  }
}

export class ArriveAddress extends OrderEntryAddress {
  constructor(value: FormAddress) {
    super({
      name: 'arrive.address',
      value,
      formLabel: 'כתובת מלאה',
      label: 'לאן',
      formErrorMessageRequired: 'נא לבחור כתובת מלאה',
    });
  }

  validForSubmission(siblingEntries: OrderFormEntries): boolean {
    return this.isValid();
  }
}

export class DepartFloor extends OrderEntryText {
  constructor(value: string) {
    super({
      name: 'depart.floor',
      value,
      defaultValue: '',
      formLabel: 'קומה',
      label: 'קומה',
      formErrorMessageRequired: 'נא לציין קומה',
      formErrorMessageMaxLen: 'לא יותר מ-30 תווים בבקשה',
      maxLen: 30,
    });
  }

  validForSubmission(siblingEntries: OrderFormEntries): boolean {
    return this.isValid();
  }
}

export class DepartRooms extends OrderEntrySelect {
  static options = [
    {value: '1', formLabel: '1', label: '1'},
    {value: '1.5', formLabel: '1.5', label: '1.5'},
    {value: '2', formLabel: '2', label: '2'},
    {value: '2.5', formLabel: '2.5', label: '2.5'},
    {value: '3', formLabel: '3', label: '3'},
    {value: '3.5', formLabel: '3.5', label: '3.5'},
    {value: '4', formLabel: '4', label: '4'},
    {value: '4.5', formLabel: '4.5', label: '4.5'},
    {value: '5', formLabel: '5', label: '5'},
    {value: '5.5', formLabel: '5.5', label: '5.5'},
    {value: '6', formLabel: '6', label: '6'},
    {value: '6.5', formLabel: '6.5', label: '6.5'},
    {value: '7', formLabel: '7', label: '7'},
    {value: '7.5', formLabel: '7.5', label: '7.5'},
    {value: '8', formLabel: '8', label: '8'},
    {value: '8.5', formLabel: '8.5', label: '8.5'},
    {value: '9', formLabel: '9', label: '9'},
    {value: '9.5', formLabel: '9.5', label: '9.5'},
    {value: '10', formLabel: '10', label: '10'},
    {value: '10+', formLabel: 'יותר מ-10', label: 'יותר מ-10'},
  ];

  constructor(value: string) {
    super({
      name: 'depart.rooms',
      value,
      defaultValue: '',
      formLabel: 'כמה חדרים',
      label: 'חדרים',
      formErrorMessageRequired: 'נא לבחור מספר חדרים',
      options: DepartRooms.options,
    });
  }

  validForSubmission(siblingEntries: OrderFormEntries): boolean {
    if (siblingEntries.orderServiceGroup.value !== 'moving') {
      return true;
    }
    return this.isValid();
  }
}

export class DepartParking extends OrderEntrySelect {
  static options = [
    {value: 'yes', formLabel: 'כן', label: 'כן', messageDisplay: 'יש חניה'},
    {value: 'no', formLabel: 'לא', label: 'לא', messageDisplay: 'אין חניה'},
    {value: 'maybe', formLabel: 'אולי (נא לפרט)', label: 'אולי', messageDisplay: 'אולי יש'},
  ];

  constructor(value: string) {
    super({
      name: 'depart.parking',
      value,
      defaultValue: '',
      formLabel: 'האם יש למשאית חניה נוחה וקרובה להעמסה?',
      label: 'חניה',
      formErrorMessageRequired: 'לציין האם יש חניה',
      options: DepartParking.options,
    });
  }

  toMessageValueDisplay(entries) {
    return entryWithCommentsValueDisplay(this.value, this.options, ['no', 'maybe'], entries.departParkingComments.toMessageValueDisplay());
  }

  validForSubmission(siblingEntries: OrderFormEntries): boolean {
    return this.isValid();
  }
}

export class DepartParkingComments extends OrderEntryText {
  constructor(value: string) {
    super({
      name: 'depart.parkingComments',
      value,
      defaultValue: '',
      formLabel: 'הערות נוספות על החניה',
      label: 'הערות על החניה',
      formErrorMessageMaxLen: 'לא יותר מ-100 תווים בבקשה',
      maxLen: 100,
    });
  }

  validForSubmission(siblingEntries: OrderFormEntries): boolean {
    return this.isValid();
  }

  isVisible({departParking}): boolean {
    return ['maybe', 'no'].includes(departParking.value);
  }
}

export class DepartLift extends OrderEntrySelect {
  static options = [
    {value: 'regular-lift', formLabel: 'מעלית רגילה', label: 'מעלית רגילה'},
    {value: 'no-lift', formLabel: 'אין מעלית', label: 'אין מעלית'},
    {value: 'small-lift', formLabel: 'מעלית קטנה', label: 'מעלית קטנה'},
    {value: 'large-lift', formLabel: 'מעלית משא', label: 'מעלית משא'},
  ];

  constructor(value: string) {
    super({
      name: 'depart.lift',
      value,
      defaultValue: '',
      formLabel: 'האם יש מעלית בבניין?',
      label: 'מעלית',
      formErrorMessageRequired: 'נא לבחור מעלית',
      options: DepartLift.options,
    });
  }

  validForSubmission(siblingEntries: OrderFormEntries): boolean {
    return this.isValid();
  }
}

export class DepartCrane extends OrderEntrySelect {
  static options = [
    {value: 'yes', formLabel: 'כן', label: 'כן', messageDisplay: 'צריך מנוף'},
    {value: 'no', formLabel: 'לא', label: 'לא', messageDisplay: 'לא צריך מנוף'},
    {value: 'maybe', formLabel: 'אולי (נא לפרט)', label: 'אולי', messageDisplay: 'אולי'},
  ];

  constructor(value: string) {
    super({
      name: 'depart.crane',
      value,
      defaultValue: '',
      formLabel: 'ידרש מנוף?',
      label: 'מנוף',
      formErrorMessageRequired: 'נא לציין אם יידרש מנוף',
      options: DepartCrane.options,
    });
  }

  toMessageValueDisplay({departCraneComments}) {
    return entryWithCommentsValueDisplay(this.value, this.options, ['yes', 'maybe'], departCraneComments.toMessageValueDisplay());
  }

  validForSubmission(siblingEntries: OrderFormEntries): boolean {
    return this.isValid();
  }
}

export class DepartCraneComments extends OrderEntryText {
  constructor(value: string) {
    super({
      name: 'depart.craneComments',
      value,
      defaultValue: '',
      formLabel: 'הערות נוספות על המנוף',
      label: 'הערות על המנוף',
      formErrorMessageMaxLen: 'לא יותר מ-100 תווים בבקשה',
      maxLen: 100,
    });
  }

  validForSubmission(siblingEntries:OrderFormEntries): boolean {
    return this.isValid();
  }

  isVisible(siblingEntries: OrderFormEntries): boolean {
    return ['maybe', 'yes'].includes(siblingEntries.departCrane.value);
  }
}

export class DepartExternalSpace extends OrderEntryMultiselect {
  static options = [
    {value: 'balcony', formLabel: 'מרפסת', label: 'מרפסת'},
    {value: 'garden', formLabel: 'גינה', label: 'גינה'},
    {value: 'roof', formLabel: 'גג', label: 'גג'},
    {value: 'none', formLabel: 'אין', label: 'אין'},
  ];

  constructor(value: string[]) {
    super({
      name: 'depart.externalSpace',
      value,
      defaultValue: [],
      formLabel: 'שטחים חיצוניים',
      label: 'שטחים חיצוניים',
      formErrorMessageRequired: 'נא לציין אם יש שטחים חיצוניים לבית',
      options: DepartExternalSpace.options,
    });
  }

  validForSubmission(siblingEntries: OrderFormEntries): boolean {
    if (siblingEntries.orderServiceGroup.value !== 'moving') {
      return true;
    }
    if (this.value.includes('balcony')) {
      return siblingEntries.departBalconyCount.isValid();
    } else {
      return this.isValid();
    }
  }
}

export class DepartBalconyCount extends OrderEntrySelect {
  static options = [
    {value: '1', formLabel: '1', label: '1', messageDisplay: 'מרפסת אחת'},
    {value: '2', formLabel: '2', label: '2', messageDisplay: 'שתי מרפסות'},
    {value: '3', formLabel: '3', label: '3', messageDisplay: '3 מרפסות'},
    {value: '4', formLabel: '4', label: '4', messageDisplay: '4 מרפסות'},
    {value: '5', formLabel: '5', label: '5', messageDisplay: '5 מרפסות'},
    {value: '6', formLabel: '6', label: '6', messageDisplay: '6 מרפסות'},
    {value: '7', formLabel: '7', label: '7', messageDisplay: '7 מרפסות'},
    {value: '8', formLabel: '8', label: '8', messageDisplay: '8 מרפסות'},
    {value: '9', formLabel: '9', label: '9', messageDisplay: '9 מרפסות'},
    {value: '10', formLabel: '10', label: '10', messageDisplay: '10 מרפסות'},
    {value: '10+', formLabel: 'יותר מ-10', label: 'יותר מ-10', messageDisplay: 'יותר מ-10 מרפסות'},
  ];

  constructor(value: string) {
    super({
      name: 'depart.balconyCount',
      value,
      defaultValue: '',
      formLabel: 'כמה מרפסות יש בבית?',
      label: 'מרפסות',
      formErrorMessageRequired: 'נא לציין מספר מרפסות',
      options: DepartBalconyCount.options,
    });
  }

  validForSubmission(siblingEntries: OrderFormEntries): boolean {
    if (siblingEntries.orderServiceGroup.value !== 'moving') {
      return true;
    }
    if (siblingEntries.departExternalSpace.value.includes('balcony')) {
      return this.isValid();
    } else {
      return true;
    }
  }

  isVisible(siblingEntries: OrderFormEntries): boolean {
    return siblingEntries.departExternalSpace.value.includes('balcony');
  }
}

export class DepartHouseStructure extends OrderEntrySelect {
  static options = [
    {value: 'gallery', formLabel: 'גלריה', label: 'גלריה'},
    {value: 'one-floor', formLabel: 'קומה אחת', label: 'קומה אחת'},
    {value: 'more-floors', formLabel: 'יותר מקומה אחת', label: 'יותר מקומה אחת'},
  ];

  constructor(value: string) {
    super({
      name: 'depart.houseStructure',
      value,
      defaultValue: '',
      formLabel: 'האם יש לכם בבית / בדירה:',
      label: 'יש בדירה',
      formErrorMessageRequired: 'נא לבחור אפשרות של קומות',
      options: DepartHouseStructure.options,
    });
  }

  toMessageValueDisplay({departExternalSpace, departBalconyCount, departHouseFloorsNum}) {
    const sentences = [];
    if (this.value === 'gallery') {
      sentences.push(this.options.find(o => o.value === this.value)?.label);
    }
    if (this.value === 'one-floor') {
      sentences.push(this.options.find(o => o.value === this.value)?.label);
    }
    if (this.value === 'more-floors') {
      sentences.push(`${departHouseFloorsNum.options.find(o => o.value === departHouseFloorsNum.value)?.messageDisplay}`);
    }
    if (departExternalSpace.value.includes('balcony')) {
      sentences.push(departBalconyCount.options.find(o => o.value === departBalconyCount.value)?.messageDisplay);
    }
    if (departExternalSpace.value.includes('garden')) {
      sentences.push('גינה');
    }
    if (departExternalSpace.value.includes('roof')) {
      sentences.push('גג');
    }
    let text = sentences.length > 0 ? sentences.join(', ') : '-';
    text = sanitizeMessageTextSpaces(text);
    return text;
  }

  validForSubmission(siblingEntries: OrderFormEntries): boolean {
    if (siblingEntries.orderServiceGroup.value !== 'moving') {
      return true;
    }
    if (this.value === 'more-floors') {
      return siblingEntries.departHouseFloorsNum.isValid();
    } else {
      return this.isValid();
    }
  }
}

export class DepartHouseFloorsNum extends OrderEntrySelect {
  static options = [
    {value: '2', formLabel: '2', label: '2', messageDisplay: 'שתי קומות'},
    {value: '3', formLabel: '3', label: '3', messageDisplay: '3 קומות'},
    {value: '4', formLabel: '4', label: '4', messageDisplay: '4 קומות'},
    {value: '5', formLabel: '5', label: '5', messageDisplay: '5 קומות'},
    {value: '5+', formLabel: 'יותר מ-5', label: 'יותר מ-5', messageDisplay: 'יותר מ-5 קומות'},
  ];

  constructor(value: string) {
    super({
      name: 'depart.houseFloorsNum',
      value,
      defaultValue: '',
      formLabel: 'כמה קומות יש בבית?',
      label: 'קומות',
      formErrorMessageRequired: 'נא לציין מספר קומות',
      options: DepartHouseFloorsNum.options,
    });
  }

  validForSubmission(siblingEntries: OrderFormEntries): boolean {
    if (siblingEntries.orderServiceGroup.value !== 'moving') {
      return true;
    }
    if (siblingEntries.departHouseStructure.value.includes('more-floors')) {
      return this.isValid();
    } else {
      return true;
    }
  }

  isVisible(siblingEntries: OrderFormEntries): boolean {
    return siblingEntries.departHouseStructure.value.includes('more-floors');
  }
}

export class ArriveFloor extends OrderEntryText {
  constructor(value: string) {
    super({
      name: 'arrive.floor',
      value,
      defaultValue: '',
      formLabel: 'קומה',
      label: 'קומה',
      formErrorMessageRequired: 'נא לציין קומה',
      formErrorMessageMaxLen: 'לא יותר מ-30 תווים בבקשה',
      maxLen: 30,
    });
  }

  validForSubmission(siblingEntries: OrderFormEntries): boolean {
    return this.isValid();
  }
}

export class ArriveParking extends OrderEntrySelect {
  static options = [
    {value: 'yes', formLabel: 'כן', label: 'כן'},
    {value: 'no', formLabel: 'לא', label: 'לא'},
    {value: 'maybe', formLabel: 'אולי (נא לפרט)', label: 'אולי'},
  ];

  constructor(value: string) {
    super({
      name: 'arrive.parking',
      value,
      defaultValue: '',
      formLabel: 'האם יש למשאית חניה נוחה וקרובה להעמסה?',
      label: 'חניה',
      formErrorMessageRequired: 'לציין האם יש חניה',
      options: ArriveParking.options,
    });
  }

  toMessageValueDisplay(siblingEntries: OrderFormEntries) {
    return entryWithCommentsValueDisplay(this.value, this.options, ['no', 'maybe'], siblingEntries.arriveParkingComments.toMessageValueDisplay(siblingEntries));
  }

  validForSubmission(siblingEntries: OrderFormEntries): boolean {
    return this.isValid();
  }
}

export class ArriveParkingComments extends OrderEntryText {
  constructor(value: string) {
    super({
      name: 'arrive.parkingComments',
      value,
      defaultValue: '',
      formLabel: 'הערות נוספות על החניה',
      label: 'הערות על החניה',
      formErrorMessageMaxLen: 'לא יותר מ-100 תווים בבקשה',
      maxLen: 100,
    });
  }

  validForSubmission(siblingEntries: OrderFormEntries): boolean {
    return this.isValid();
  }

  isVisible(siblingEntries: OrderFormEntries): boolean {
    return ['maybe', 'no'].includes(siblingEntries.arriveParking.value);
  }
}

export class ArriveLift extends OrderEntrySelect {
  static options = [
    {value: 'regular-lift', formLabel: 'מעלית רגילה', label: 'מעלית רגילה'},
    {value: 'no-lift', formLabel: 'אין מעלית', label: 'אין מעלית'},
    {value: 'small-lift', formLabel: 'מעלית קטנה', label: 'מעלית קטנה'},
    {value: 'large-lift', formLabel: 'מעלית משא', label: 'מעלית משא'},
  ];

  constructor(value: string) {
    super({
      name: 'arrive.lift',
      value,
      defaultValue: '',
      formLabel: 'האם יש מעלית בבניין?',
      label: 'מעלית',
      formErrorMessageRequired: 'נא לבחור מעלית',
      options: ArriveLift.options,
    });
  }

  validForSubmission(siblingEntries: OrderFormEntries): boolean {
    return this.isValid();
  }
}

export class ArriveCrane extends OrderEntrySelect {
  static options = [
    {value: 'yes', formLabel: 'כן', label: 'כן'},
    {value: 'no', formLabel: 'לא', label: 'לא'},
    {value: 'maybe', formLabel: 'אולי (נא לפרט)', label: 'אולי'},
  ];

  constructor(value: string) {
    super({
      name: 'arrive.crane',
      value,
      defaultValue: '',
      formLabel: 'ידרש מנוף?',
      label: 'מנוף',
      formErrorMessageRequired: 'נא לציין אם יידרש מנוף',
      options: ArriveCrane.options,
    });
  }

  toMessageValueDisplay(siblingEntries: OrderFormEntries) {
    return entryWithCommentsValueDisplay(this.value, this.options, ['yes', 'maybe'], siblingEntries.arriveCraneComments.toMessageValueDisplay(siblingEntries));
  }

  validForSubmission(siblingEntries: OrderFormEntries): boolean {
    return this.isValid();
  }
}

export class ArriveCraneComments extends OrderEntryText {
  constructor(value: string) {
    super({
      name: 'arrive.craneComments',
      value,
      defaultValue: '',
      formLabel: 'הערות נוספות על המנוף',
      label: 'הערות על המנוף',
      formErrorMessageMaxLen: 'לא יותר מ-100 תווים בבקשה',
      maxLen: 100,
    });
  }

  validForSubmission(siblingEntries: OrderFormEntries): boolean {
    return this.isValid();
  }

  isVisible(siblingEntries: OrderFormEntries): boolean {
    return ['maybe', 'yes'].includes(siblingEntries.arriveCrane.value);
  }
}

export class ArriveHouseStructure extends OrderEntrySelect {
  static options = [
    {value: 'gallery', formLabel: 'גלריה', label: 'גלריה'},
    {value: 'one-floor', formLabel: 'קומה אחת', label: 'קומה אחת'},
    {value: 'more-floors', formLabel: 'יותר מקומה אחת', label: 'יותר מקומה אחת'},
  ];

  constructor(value: string) {
    super({
      name: 'depart.houseStructure',
      value,
      defaultValue: '',
      formLabel: 'האם יש לכם בבית / בדירה:',
      label: 'יש בדירה',
      formErrorMessageRequired: 'נא לבחור אפשרות של קומות בדירה',
      options: ArriveHouseStructure.options,
    });
  }

  toMessageValueDisplay(siblingEntries: OrderFormEntries) {
    let text = '-';
    if (this.value === 'gallery') {
      text = this.options.find(o => o.value === this.value)?.label || '-';
    }
    if (this.value === 'one-floor') {
      text = this.options.find(o => o.value === this.value)?.label || '-';
    }
    if (this.value === 'more-floors') {
      text = siblingEntries.arriveHouseFloorsNum.options.find(o => o.value === siblingEntries.arriveHouseFloorsNum.value)?.messageDisplay || '-';
    }
    text = sanitizeMessageTextSpaces(text);
    return text;
  }

  validForSubmission(siblingEntries: OrderFormEntries): boolean {
    if (siblingEntries.orderServiceGroup.value !== 'moving') {
      return true;
    }
    if (this.value === 'more-floors') {
      return siblingEntries.arriveHouseFloorsNum.isValid();
    } else {
      return this.isValid();
    }
  }
}

export class ArriveHouseFloorsNum extends OrderEntrySelect {
  static options = [
    {value: '2', formLabel: '2', label: '2', messageDisplay: 'שתי קומות'},
    {value: '3', formLabel: '3', label: '3', messageDisplay: '3 קומות'},
    {value: '4', formLabel: '4', label: '4', messageDisplay: '4 קומות'},
    {value: '5', formLabel: '5', label: '5', messageDisplay: '5 קומות'},
    {value: '5+', formLabel: 'יותר מ-5', label: 'יותר מ-5', messageDisplay: 'יותר מ-5 קומות'},
  ];

  constructor(value: string) {
    super({
      name: 'depart.houseFloorsNum',
      value,
      defaultValue: '',
      formLabel: 'כמה קומות יש בבית?',
      label: 'קומות',
      formErrorMessageRequired: 'נא לציין מספר קומות',
      options: ArriveHouseFloorsNum.options,
    });
  }

  validForSubmission(siblingEntries: OrderFormEntries): boolean {
    if (siblingEntries.orderServiceGroup.value !== 'moving') {
      return true;
    }
    if (siblingEntries.arriveHouseStructure.value.includes('more-floors')) {
      return this.isValid();
    } else {
      return true;
    }
  }

  isVisible(siblingEntries: OrderFormEntries): boolean {
    return siblingEntries.arriveHouseStructure.value.includes('more-floors');
  }
}

export class OrderDateDay extends OrderEntryDay {
  constructor(value: string) {
    super({
      name: 'dateDay',
      value,
      defaultValue: null,
      formLabel: `תאריך הובלה (עד ${rules.maxOrderDateFutureDays} יום קדימה)`,
      label: 'מתי',
      formErrorMessageRequired: 'נא לבחור תאריך הובלה',
      formErrorMessageFutureDate: `ניתן לבחור תאריך הובלה עד ${rules.maxOrderDateFutureDays} יום קדימה`,
      maxFutureDays: rules.maxOrderDateFutureDays,
    });
  }

  validForSubmission(siblingEntries: OrderFormEntries): boolean {
    return this.isValid();
  }
}

export class OrderDateDayHours extends OrderEntryMultiselect {
  constructor(value: string[]) {
    super({
      name: 'dateDayHours',
      value,
      defaultValue: [],
      formLabel: 'באיזה שעות?',
      label: 'שעות',
      formErrorMessageRequired: 'נא לבחור שעות מעבר',
      options: [
        {value: 'morning', formLabel: 'בוקר', label: 'בוקר'},
        {value: 'noon', formLabel: 'צהריים', label: 'צהריים'},
        {value: 'evening', formLabel: 'ערב', label: 'ערב'},
      ],
    });
  }

  validForSubmission(siblingEntries: OrderFormEntries): boolean {
    if (siblingEntries.orderServiceGroup.value !== 'moving') {
      return true;
    }
    return this.isValid();
  }
}

export class BagsCount extends OrderEntryNumber {
  constructor(value: number) {
    super({
      name: 'bagsCount',
      value,
      defaultValue: 0,
      formLabel: 'כמה תיקים?',
      label: 'תיקים',
      min: 0,
    });
  }

  toMessageValueDisplay(siblingEntries: OrderFormEntries) {
    const sentences = [];
    if (this.value > 0) {
      sentences.push(`${this.value} ${this.label}`);
    }
    if (siblingEntries.suitcasesCount.value > 0) {
      sentences.push(`${siblingEntries.suitcasesCount.value} ${siblingEntries.suitcasesCount.label}`);
    }
    if (siblingEntries.sacksCount.value > 0) {
      sentences.push(`${siblingEntries.sacksCount.value} ${siblingEntries.sacksCount.label}`);
    }
    if (siblingEntries.boxesCount.value > 0) {
      sentences.push(`${siblingEntries.boxesCount.value} ${siblingEntries.boxesCount.label}`);
    }
    if (siblingEntries.builderBagsCount.value > 0) {
      sentences.push(`${siblingEntries.builderBagsCount.value} ${siblingEntries.builderBagsCount.label}`);
    }
    let text = sentences.length > 0 ? sentences.join(', ') : '-';
    text = sanitizeMessageTextSpaces(text);
    return text;
  }

  validForSubmission(siblingEntries: OrderFormEntries): boolean {
    if (siblingEntries.orderServiceGroup.value !== 'moving') {
      return true;
    }
    return this.isValid();
  }
}

export class SuitcasesCount extends OrderEntryNumber {
  constructor(value: number) {
    super({
      name: 'suitcasesCount',
      value,
      defaultValue: 0,
      formLabel: 'כמה מזוודות?',
      label: 'מזוודות',
      min: 0,
    });
  }

  validForSubmission(siblingEntries: OrderFormEntries): boolean {
    if (siblingEntries.orderServiceGroup.value !== 'moving') {
      return true;
    }
    return this.isValid();
  }
}

export class SacksCount extends OrderEntryNumber {
  constructor(value: number) {
    super({
      name: 'sacksCount',
      value,
      defaultValue: 0,
      formLabel: 'כמה שקיות?',
      label: 'שקיות',
      min: 0,
    });
  }

  validForSubmission(siblingEntries: OrderFormEntries): boolean {
    if (siblingEntries.orderServiceGroup.value !== 'moving') {
      return true;
    }
    return this.isValid();
  }
}

export class BuilderBagsCount extends OrderEntryNumber {
  constructor(value: number) {
    super({
      name: 'builderBagsCount',
      value,
      defaultValue: 0,
      formLabel: 'כמה באלות (שקים ענקיים)?',
      label: 'באלות (שקים ענקיים)',
      min: 0,
    });
  }

  validForSubmission(siblingEntries: OrderFormEntries): boolean {
    if (siblingEntries.orderServiceGroup.value !== 'moving') {
      return true;
    }
    return this.isValid();
  }
}

export class BoxesCount extends OrderEntryNumber {
  constructor(value: number) {
    super({
      name: 'boxesCount',
      value,
      defaultValue: 0,
      formLabel: 'כמה ארגזים?',
      formSubtitle: 'עד גודל 40x60 ס״מ',
      label: 'ארגזים (עד גודל 40x60 ס״מ)',
      min: 0,
    });
  }

  validForSubmission(siblingEntries: OrderFormEntries): boolean {
    if (siblingEntries.orderServiceGroup.value !== 'moving') {
      return true;
    }
    return this.isValid();
  }
}

export class SmallMovingBoxesCount extends OrderEntryNumber {
  constructor(value: number) {
    super({
      name: 'smallMovingBoxesCount',
      value,
      defaultValue: 0,
      formLabel: 'כמה ארגזים (עד 40x60) / שקים / מזוודות?',
      label: 'ארגזים (עד 40x60) / שקים / מזוודות',
      min: 0,
    });
  }

  validForSubmission(siblingEntries: OrderFormEntries): boolean {
    if (siblingEntries.orderServiceGroup.value !== 'smallMoving') {
      return true;
    }
    return this.isValid();
  }
}

export class OrderComments extends OrderEntryText {
  constructor(value: string) {
    super({
      name: 'orderComments',
      value,
      defaultValue: '',
      formLabel: 'הערות נוספות להובלה',
      label: 'הערות להובלה',
      formErrorMessageMaxLen: 'לא יותר מ-200 תווים בבקשה',
      maxLen: 200,
    });
  }

  toMessageValueDisplay(siblingEntries: OrderFormEntries) {
    return sanitizeMessageTextSpaces(this.value) || '-';
  }

  validForSubmission(siblingEntries: OrderFormEntries): boolean {
    return this.isValid();
  }
}

export class OrderAdditionalOffers extends OrderEntryMultiselect {
  static options = [
    {value: 'סיוד', formLabel: 'סיוד', label: 'סיוד'},
    {value: 'ניקיון', formLabel: 'ניקיון', label: 'ניקיון'},
    {value: 'אחסנה', formLabel: 'אחסנה', label: 'אחסנה'},
  ];

  constructor(value: string[]) {
    super({
      name: 'additionalOffers',
      value,
      defaultValue: [],
      formLabel: 'לצרכים סטטיסטיים - האם בנוסף להובלה תרצו לקבל הצעת מחיר לאחד או יותר מהשירותים הבאים?',
      label: 'הצעות',
      options: OrderAdditionalOffers.options,
    });
  }

  validForSubmission(siblingEntries: OrderFormEntries): boolean {
    return this.isValid();
  }
}

export class OrderServiceGroup extends OrderEntrySelect {
  static options = [
    {value: 'moving', formLabel: 'הובלת דירה', label: 'הובלת דירה'},
    {value: 'smallMoving', formLabel: 'הובלה קטנה', label: 'הובלה קטנה'},
  ];

  constructor(value: string) {
    super({
      name: 'orderServiceGroup',
      value,
      defaultValue: 'moving',
      formLabel: 'סוג הובלה',
      label: 'סוג הובלה',
      formErrorMessageRequired: 'נא לבחור סוג הובלה',
      options: OrderServiceGroup.options,
    });
  }

  validForSubmission(siblingEntries: OrderFormEntries): boolean {
    return this.isValid();
  }
}

export class PackingMaterials extends OrderEntrySelect {
  static options = [
    {value: 'yes', formLabel: 'כן', label: 'כן'},
    {value: 'no', formLabel: 'אין צורך', label: 'לא'},
  ];

  constructor(value: string) {
    super({
      name: 'packingMaterials',
      value,
      defaultValue: '',
      formLabel: 'ארגזים וחומרי אריזה',
      label: 'ארגזים וחומרי אריזה',
      formErrorMessageRequired: 'נא לציין אם תרצו חומרי אריזה',
      formSubtitle: 'חברת ההובלה תספק את הקרטונים, נייר פצפצים (ניילון בועות-אוויר), מסקנטייפים וכל מה שנדרש לאריזת הבית',
      options: PackingMaterials.options,
    });
  }

  validForSubmission(siblingEntries: OrderFormEntries): boolean {
    if (siblingEntries.orderServiceGroup.value !== 'moving') {
      return true;
    }
    return this.isValid();
  }
}

export class PackingService extends OrderEntrySelect {
  static options = [
    {value: 'yes', formLabel: 'כן', label: 'כן'},
    {value: 'no', formLabel: 'אין צורך', label: 'לא'},
  ];

  constructor(value: string) {
    super({
      name: 'packingService',
      value,
      defaultValue: '',
      formLabel: 'אריזת הבית',
      label: 'אריזת הבית',
      formSubtitle: 'חברת ההובלה תארוז לכם את הבית ביום ההובלה',
      formErrorMessageRequired: 'נא לציין אם תרצו שירותי אריזת הבית',
      options: PackingService.options,
    });
  }

  validForSubmission(siblingEntries: OrderFormEntries): boolean {
    if (siblingEntries.orderServiceGroup.value !== 'moving') {
      return true;
    }
    return this.isValid();
  }
}

export class UnpackingService extends OrderEntrySelect {
  static options = [
    {value: 'yes', formLabel: 'כן', label: 'כן'},
    {value: 'no', formLabel: 'אין צורך', label: 'לא'},
  ];

  constructor(value: string) {
    super({
      name: 'unpackingService',
      value,
      defaultValue: '',
      formLabel: 'פריקה וסידור הבית',
      label: 'פריקה וסידור הבית',
      formSubtitle: 'חברת סדר וארגון תפרוק לכם את התכולה, תמיין ותסדר',
      formErrorMessageRequired: 'נא לציין אם תרצו שירותי פריקה וסידור הבית',
      options: UnpackingService.options,
    });
  }

  validForSubmission(siblingEntries: OrderFormEntries): boolean {
    if (siblingEntries.orderServiceGroup.value !== 'moving') {
      return true;
    }
    return this.isValid();
  }
}

export class UnpackingDateDay extends OrderEntryDay {
  constructor(value: string) {
    super({
      name: 'unpackingDateDay',
      value,
      defaultValue: null,
      formLabel: `תאריך פריקה וסידור (עד ${rules.maxOrderDateFutureDays} יום קדימה)`,
      label: 'תאריך פריקה',
      formErrorMessageRequired: 'נא לבחור תאריך לפריקה וסידור הבית',
      formErrorMessageFutureDate: `ניתן לבחור תאריך פריקה וסידור הבית עד ${rules.maxOrderDateFutureDays} יום קדימה`,
      maxFutureDays: rules.maxOrderDateFutureDays,
    });
  }

  validForSubmission(siblingEntries: OrderFormEntries): boolean {
    if (siblingEntries.orderServiceGroup.value !== 'moving') {
      return true;
    }
    if (siblingEntries.unpackingService.value === 'yes') {
      return this.isValid();
    } else {
      return true;
    }
  }
}

export class UnpackingRooms extends OrderEntrySelect {
  static options = [
    {value: '1', formLabel: '1', label: '1'},
    {value: '1.5', formLabel: '1.5', label: '1.5'},
    {value: '2', formLabel: '2', label: '2'},
    {value: '2.5', formLabel: '2.5', label: '2.5'},
    {value: '3', formLabel: '3', label: '3'},
    {value: '3.5', formLabel: '3.5', label: '3.5'},
    {value: '4', formLabel: '4', label: '4'},
    {value: '4.5', formLabel: '4.5', label: '4.5'},
    {value: '5', formLabel: '5', label: '5'},
    {value: '5.5', formLabel: '5.5', label: '5.5'},
    {value: '6', formLabel: '6', label: '6'},
    {value: '6.5', formLabel: '6.5', label: '6.5'},
    {value: '7', formLabel: '7', label: '7'},
    {value: '7.5', formLabel: '7.5', label: '7.5'},
    {value: '8', formLabel: '8', label: '8'},
    {value: '8.5', formLabel: '8.5', label: '8.5'},
    {value: '9', formLabel: '9', label: '9'},
    {value: '9.5', formLabel: '9.5', label: '9.5'},
    {value: '10', formLabel: '10', label: '10'},
    {value: '10+', formLabel: 'יותר מ-10', label: 'יותר מ-10'},
  ];

  constructor(value: string) {
    super({
      name: 'unpackingRooms',
      value,
      defaultValue: '',
      formLabel: 'מספר חדרים לסידור',
      label: 'חדרים לסידור',
      formErrorMessageRequired: 'נא לבחור מספר חדרים לסידור',
      options: UnpackingRooms.options,
    });
  }

  validForSubmission(siblingEntries: OrderFormEntries): boolean {
    if (siblingEntries.orderServiceGroup.value !== 'moving') {
      return true;
    }
    if (siblingEntries.unpackingService.value === 'yes') {
      return this.isValid();
    } else {
      return true;
    }
  }
}

export class UnpackingClosetsCount extends OrderEntryNumber {
  constructor(value: number) {
    super({
      name: 'unpackingClosetsCount',
      value,
      defaultValue: 0,
      formLabel: 'מספר ארונות לסידור',
      label: 'ארונות לסידור',
      min: 0,
    });
  }

  validForSubmission(siblingEntries: OrderFormEntries): boolean {
    if (siblingEntries.orderServiceGroup.value !== 'moving') {
      return true;
    }
    if (siblingEntries.unpackingService.value === 'yes') {
      return this.isValid();
    } else {
      return true;
    }
  }
}

export class UnpackingKitchenCount extends OrderEntryNumber {
  constructor(value: number) {
    super({
      name: 'unpackingKitchenCount',
      value,
      defaultValue: 0,
      formLabel: 'מספר ארונות מטבח לסידור',
      label: 'ארונות מטבח לסידור',
      min: 0,
    });
  }

  validForSubmission(siblingEntries: OrderFormEntries): boolean {
    if (siblingEntries.unpackingService.value === 'yes') {
      return this.isValid();
    } else {
      return true;
    }
  }
}

export class UnpackingBathroomCount extends OrderEntryNumber {
  constructor(value: number) {
    super({
      name: 'unpackingBathroomCount',
      value,
      defaultValue: 0,
      formLabel: 'מספר ארונות אמבטיה לסידור',
      label: 'ארונות אמבטיה לסידור',
      min: 0,
    });
  }

  validForSubmission(siblingEntries: OrderFormEntries): boolean {
    if (siblingEntries.orderServiceGroup.value !== 'moving') {
      return true;
    }
    if (siblingEntries.unpackingService.value === 'yes') {
      return this.isValid();
    } else {
      return true;
    }
  }
}

export class UnpackingBagsCount extends OrderEntryNumber {
  constructor(value: number) {
    super({
      name: 'unpackingBagsCount',
      value,
      defaultValue: 0,
      formLabel: 'כמות תיקים לפריקה וסידור',
      formErrorMessageRequired: 'נא לציין כמות תיקים לפריקה',
      label: 'תיקים לפריקה',
      min: 0,
    });
  }

  toSummaryDisplay(siblingEntries: OrderFormEntries) {
    if (siblingEntries.unpackingHasBags.value === 'no') {
      return 'אין';
    } else {
      return String(this.value);
    }
  }

  validForSubmission(siblingEntries: OrderFormEntries): boolean {
    if (siblingEntries.orderServiceGroup.value !== 'moving') {
      return true;
    }
    if (siblingEntries.unpackingService.value === 'no') {
      return true;
    }
    if (siblingEntries.unpackingHasBags.value === 'no') {
      return true;
    } else {
      return this.value > 0;
    }
  }
}

export class UnpackingSuitcasesCount extends OrderEntryNumber {
  constructor(value: number) {
    super({
      name: 'unpackingSuitcasesCount',
      value,
      defaultValue: 0,
      formLabel: 'כמות מזוודות לפריקה וסידור',
      label: 'מזוודות לפריקה',
      min: 0,
    });
  }

  toSummaryDisplay(siblingEntries: OrderFormEntries) {
    if (siblingEntries.unpackingHasSuitcases.value === 'no') {
      return 'אין';
    } else {
      return String(this.value);
    }
  }

  validForSubmission(siblingEntries: OrderFormEntries): boolean {
    if (siblingEntries.orderServiceGroup.value !== 'moving') {
      return true;
    }
    if (siblingEntries.unpackingService.value === 'no') {
      return true;
    }
    if (siblingEntries.unpackingHasSuitcases.value === 'no') {
      return true;
    } else {
      return this.value > 0;
    }
  }
}

export class UnpackingSacksCount extends OrderEntryNumber {
  constructor(value: number) {
    super({
      name: 'unpackingSacksCount',
      value,
      defaultValue: 0,
      formLabel: 'כמות שקיות לפריקה וסידור',
      label: 'שקיות לפריקה',
      min: 0,
    });
  }

  toSummaryDisplay(siblingEntries: OrderFormEntries) {
    if (siblingEntries.unpackingHasSacks.value === 'no') {
      return 'אין';
    } else {
      return String(this.value);
    }
  }

  validForSubmission(siblingEntries: OrderFormEntries): boolean {
    if (siblingEntries.orderServiceGroup.value !== 'moving') {
      return true;
    }
    if (siblingEntries.unpackingService.value === 'no') {
      return true;
    }
    if (siblingEntries.unpackingHasSacks.value === 'no') {
      return true;
    } else {
      return this.value > 0;
    }
  }
}

export class UnpackingBoxesCount extends OrderEntryNumber {
  constructor(value: number) {
    super({
      name: 'boxesCount',
      value,
      defaultValue: 0,
      formLabel: 'כמות ארגזים לפריקה וסידור',
      label: 'ארגזים לפריקה',
      min: 0,
    });
  }

  toSummaryDisplay(siblingEntries: OrderFormEntries) {
    if (siblingEntries.unpackingHasBoxes.value === 'no') {
      return 'אין';
    } else {
      return String(this.value);
    }
  }

  validForSubmission(siblingEntries: OrderFormEntries): boolean {
    if (siblingEntries.orderServiceGroup.value !== 'moving') {
      return true;
    }
    if (siblingEntries.unpackingService.value === 'no') {
      return true;
    }
    if (siblingEntries.unpackingHasBoxes.value === 'no') {
      return true;
    } else {
      return this.value > 0;
    }
  }
}

export class UnpackingBuilderBagsCount extends OrderEntryNumber {
  constructor(value: number) {
    super({
      name: 'unpackingBuilderBagsCount',
      value,
      defaultValue: 0,
      formLabel: 'כמות שקים ענקיים (באלות) לפריקה וסידור',
      label: 'שקים ענקיים (באלות) לפריקה',
      min: 0,
    });
  }

  toSummaryDisplay(siblingEntries: OrderFormEntries) {
    if (siblingEntries.unpackingHasBuilderBags.value === 'no') {
      return 'אין';
    } else {
      return String(this.value);
    }
  }

  validForSubmission(siblingEntries: OrderFormEntries): boolean {
    if (siblingEntries.orderServiceGroup.value !== 'moving') {
      return true;
    }
    if (siblingEntries.unpackingService.value === 'no') {
      return true;
    }
    if (siblingEntries.unpackingHasBuilderBags.value === 'no') {
      return true;
    } else {
      return this.value > 0;
    }
  }
}

export class UnpackingHasBoxes extends OrderEntrySelect {
  static options = [
    {value: 'yes', formLabel: 'כן', label: 'כן', messageDisplay: 'יש'},
    {value: 'no', formLabel: 'לא', label: 'לא', messageDisplay: 'אין'},
  ];

  constructor(value: string) {
    super({
      name: 'unpackingHasBoxes',
      value,
      defaultValue: '',
      formLabel: '',
      label: '',
      formErrorMessageRequired: 'נא לציין האם יש ארגזים לפריקה',
      options: UnpackingHasBoxes.options,
    });
  }

  validForSubmission(siblingEntries: OrderFormEntries): boolean {
    if (siblingEntries.orderServiceGroup.value !== 'moving') {
      return true;
    }
    if (siblingEntries.unpackingService.value === 'no') {
      return true;
    }
    return this.isValid();
  }
}

export class UnpackingHasBags extends OrderEntrySelect {
  static options = [
    {value: 'yes', formLabel: 'כן', label: 'כן', messageDisplay: 'יש'},
    {value: 'no', formLabel: 'לא', label: 'לא', messageDisplay: 'אין'},
  ];

  constructor(value: string) {
    super({
      name: 'unpackingHasBags',
      value,
      defaultValue: '',
      formLabel: '',
      label: '',
      formErrorMessageRequired: 'נא לציין האם יש תיקים לפריקה',
      options: UnpackingHasBags.options,
    });
  }

  validForSubmission(siblingEntries: OrderFormEntries): boolean {
    if (siblingEntries.orderServiceGroup.value !== 'moving') {
      return true;
    }
    if (siblingEntries.unpackingService.value === 'no') {
      return true;
    }
    return this.isValid();
  }
}

export class UnpackingHasSuitcases extends OrderEntrySelect {
  static options = [
    {value: 'yes', formLabel: 'כן', label: 'כן', messageDisplay: 'יש'},
    {value: 'no', formLabel: 'לא', label: 'לא', messageDisplay: 'אין'},
  ];

  constructor(value: string) {
    super({
      name: 'unpackingHasSuitcases',
      value,
      defaultValue: '',
      formLabel: '',
      label: '',
      formErrorMessageRequired: 'נא לציין האם יש מזוודות לפריקה',
      options: UnpackingHasSuitcases.options,
    });
  }

  validForSubmission(siblingEntries: OrderFormEntries): boolean {
    if (siblingEntries.orderServiceGroup.value !== 'moving') {
      return true;
    }
    if (siblingEntries.unpackingService.value === 'no') {
      return true;
    }
    return this.isValid();
  }
}

export class UnpackingHasSacks extends OrderEntrySelect {
  static options = [
    {value: 'yes', formLabel: 'כן', label: 'כן', messageDisplay: 'יש'},
    {value: 'no', formLabel: 'לא', label: 'לא', messageDisplay: 'אין'},
  ];

  constructor(value: string) {
    super({
      name: 'unpackingHasSacks',
      value,
      defaultValue: '',
      formLabel: '',
      label: '',
      formErrorMessageRequired: 'נא לציין האם יש שקיות לפריקה',
      options: UnpackingHasSacks.options,
    });
  }

  validForSubmission(siblingEntries: OrderFormEntries): boolean {
    if (siblingEntries.orderServiceGroup.value !== 'moving') {
      return true;
    }
    if (siblingEntries.unpackingService.value === 'no') {
      return true;
    }
    return this.isValid();
  }
}

export class UnpackingHasBuilderBags extends OrderEntrySelect {
  static options = [
    {value: 'yes', formLabel: 'כן', label: 'כן', messageDisplay: 'יש'},
    {value: 'no', formLabel: 'לא', label: 'לא', messageDisplay: 'אין'},
  ];

  constructor(value: string) {
    super({
      name: 'unpackingHasBuilderBags',
      value,
      defaultValue: '',
      formLabel: '',
      label: '',
      formErrorMessageRequired: 'נא לציין האם יש שקים ענקיים (באלות) לפריקה',
      options: UnpackingHasBuilderBags.options,
    });
  }

  validForSubmission(siblingEntries: OrderFormEntries): boolean {
    if (siblingEntries.orderServiceGroup.value !== 'moving') {
      return true;
    }
    if (siblingEntries.unpackingService.value === 'no') {
      return true;
    }
    return this.isValid();
  }
}

export class HandymanService extends OrderEntrySelect {
  static options = [
    {value: 'yes', formLabel: 'כן', label: 'כן'},
    {value: 'no', formLabel: 'אין צורך', label: 'לא'},
  ];

  constructor(value: string) {
    super({
      name: 'handymanService',
      value,
      defaultValue: '',
      formLabel: 'איש תחזוקה (הנדימן)',
      label: 'איש תחזוקה (הנדימן)',
      formSubtitle: 'איש תחזוקה יגיע לטפל לכם במה שאתם צריכים. למשל - תליית מדפים, הרכבת רהיטים, תיקוני צבע, שפכטל, תליית מנורות...',
      formErrorMessageRequired: 'נא לציין אם תרצו שירותי איש תחזוקה',
      options: HandymanService.options,
    });
  }

  validForSubmission(siblingEntries: OrderFormEntries): boolean {
    if (siblingEntries.orderServiceGroup.value !== 'moving') {
      return true;
    }
    return this.isValid();
  }
}

export class HandymanComments extends OrderEntryText {
  constructor(value: string) {
    super({
      name: 'handymanComments',
      value,
      defaultValue: '',
      formLabel: 'פירוט להנדימן',
      label: 'פירוט להנדימן',
      formErrorMessageRequired: 'נא לפרט מה אתם צריכים.', 
      formPlaceholder: 'פרטו מה אתם צריכים לבית החדש.',
      formErrorMessageMaxLen: 'לא יותר מ-500 תווים בבקשה',
      maxLen: 500,
    });
  }

  toMessageValueDisplay(siblingEntries: OrderFormEntries) {
    return sanitizeMessageTextSpaces(this.value) || '-';
  }

  validForSubmission(siblingEntries: OrderFormEntries): boolean {
    if (siblingEntries.orderServiceGroup.value !== 'moving') {
      return true;
    }
    if (siblingEntries.handymanService.value !== 'yes') {
      return true;
    }
    return this.isValid() && this.value.length > 0;
  }

  isVisible(siblingEntries: OrderFormEntries): boolean {
    return siblingEntries.handymanService.value === 'yes';
  }
}

export class LocksmithService extends OrderEntrySelect {
  static options = [
    {value: 'yes', formLabel: 'כן', label: 'כן'},
    {value: 'no', formLabel: 'אין צורך', label: 'לא'},
  ];

  constructor(value: string) {
    super({
      name: 'locksmithService',
      value,
      defaultValue: '',
      formLabel: 'מנעולן',
      label: 'מנעולן',
      formSubtitle: 'מנעולן בעל רישיון יגיע להחליף לכם מנעול.',
      formErrorMessageRequired: 'נא לציין אם תרצו שירותי מנעולן',
      options: LocksmithService.options,
    });
  }

  validForSubmission(siblingEntries: OrderFormEntries): boolean {
    if (siblingEntries.orderServiceGroup.value !== 'moving') {
      return true;
    }
    return this.isValid();
  }
}

export class ConsentTextMessages extends OrderEntrySelect {
  static options = [
    {value: 'yes', formLabel: 'כן', label: 'כן'},
  ];

  constructor(value: string) {
    super({
      name: 'consentTextMessages',
      value,
      defaultValue: '',
      formLabel: 'הודעות טקסט',
      label: 'קבלת הצעות בהודעות טקסט',
      formErrorMessageRequired: 'יש לאשר קבלת הצעות מחיר בהודעות טקסט',
      options: ConsentTextMessages.options,
    });
  }

  validForSubmission(siblingEntries: OrderFormEntries): boolean {
    if (this.value === 'yes') {
      return true;
    } else {
      return false;
    }
  }
}

export class ConsentPhoneCalls extends OrderEntrySelect {
  static options = [
    {value: 'yes', formLabel: 'כן', label: 'כן'},
    {value: 'no', formLabel: 'לא', label: 'לא'},
  ];

  constructor(value: string) {
    super({
      name: 'consentPhoneCalls',
      value,
      defaultValue: '',
      formLabel: 'גם בשיחות בטלפון',
      label: 'קבלת הצעות בשיחות טלפון',
      formErrorMessageRequired: 'נא לבחור אם ברצונכם לקבל הצעות מחיר בשיחות טלפון',
      options: ConsentPhoneCalls.options,
    });
  }

  validForSubmission(siblingEntries: OrderFormEntries): boolean {
    return this.isValid();
  }
}
