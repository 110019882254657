import { createFileRoute, Navigate } from '@tanstack/react-router'
import { usePartner } from '../../../services/auth.tsx'

export const Route = createFileRoute('/_auth/partner_/')({
  component: PartnerPage,
})

export function PartnerPage() {
  const partner = usePartner()
  if (!partner) {
    /* @ts-expect-error */
    return <Navigate to={'/partner-login'} />
  }
  /* @ts-expect-error */
  return <Navigate to={'/partner/orders'} />
}
