import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {CustomerServiceButton} from "./stage-elements.tsx";

const labels = {
  termsAndPrivacy: 'תנאי שימוש ומדיניות פרטיות',
}

export function Footer() {
  return <Stack sx={{m: 2}} gap={1}>
    <Box sx={{textAlign: 'center', fontSize: 12}}>
      <Link target="_blank"
            href="https://www.tarimli.com/terms-and-privacy">{labels.termsAndPrivacy}</Link>
      <br/>
      א.ב.א תריםלי בע"מ | 2025<br/>
      <p style={{margin: 0, direction: 'rtl', unicodeBidi: 'plaintext'}}>A.B.A TarimLi LTD.</p>
      <p style={{margin: 0, direction: 'rtl', unicodeBidi: 'plaintext'}}>YAD HAROTZIM 10, 6770003 TEL-AVIV</p>
      <p style={{margin: 0, direction: 'rtl', unicodeBidi: 'plaintext'}}>+972-515-207-969</p>
    </Box>
    <CustomerServiceButton variant={'small'} sx={{alignSelf: 'center'}}/>
  </Stack>;
}